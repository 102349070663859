// 直播中控台
import Layout from '@/layout/liveplatform'

const liveplatformRouter = [{
	path: '/home',
	component: Layout,
	redirect: '/home/dashboard',
	children: [{
		path: 'dashboard',
		name: 'Dashboard',
		component: () => import('@/views/liveplatform/home/index'),
		meta: { title: '首页', icon: 'el-icon-s-home' }
	}]
}, {
	path: '/home/live',
	component: Layout,
	redirect: '/home/live/list',
	alwaysShow: true,
	children: [{
		path: 'list',
		name: 'LiveList',
		component: () => import('@/views/liveplatform/live/index'),
		meta: { title: '直播列表' }
	}, {
		path: 'create',
		name: 'LiveCreate',
		hidden: true,
		component: () => import('@/views/liveplatform/live/create'),
		meta: { title: '创建预告', activeMenu: '/home/dashboard' }
	}, {
		path: 'edit/:id',
		name: 'LiveEdit',
		hidden: true,
		component: () => import('@/views/liveplatform/live/liveEdit'),
		meta: { title: '直播预告', activeMenu: '/home/live/list' }
	}, {
		path: 'info/:id',
		name: 'LiveInfo',
		hidden: true,
		component: () => import('@/views/liveplatform/live/liveInfo'),
		meta: { title: '直播详情', activeMenu: '/home/live/list' }
	}, {
		path: 'playback/:id',
		name: 'LivePlayback',
		hidden: true,
		component: () => import('@/views/liveplatform/live/playback'),
		meta: { title: '直播回放', activeMenu: '/home/live/list' }
	}, {
		path: 'decoration',
		name: 'LiveDecoration',
		component: () => import('@/views/liveplatform/live/decoration'),
		meta: { title: '直播装修' }
	}, {
		path: 'decoration/informationcard',
		name: 'LiveInformationcard',
		hidden: true,
		component: () => import('@/views/liveplatform/live/informationcard'),
		meta: { title: '直播信息卡', activeMenu: '/home/live/decoration' }
	}, {
		path: 'decoration/informationcard/create',
		name: 'LiveInformationcardCreate',
		hidden: true,
		component: () => import('@/views/liveplatform/live/informationcardInfo'),
		meta: { title: '创建信息卡', activeMenu: '/home/live/decoration' }
	}, {
		path: 'decoration/informationcard/edit/:id',
		name: 'LiveInformationcardEdit',
		hidden: true,
		component: () => import('@/views/liveplatform/live/informationcardInfo'),
		meta: { title: '编辑信息卡', activeMenu: '/home/live/decoration' }
	}, {
		path: 'market',
		name: 'LiveMarket',
		component: () => import('@/views/liveplatform/live/market'),
		meta: { title: '直播互动' }
	}, {
		path: 'coupons',
		name: 'LiveCoupons',
		hidden: true,
		component: () => import('@/views/liveplatform/live/coupons'),
		meta: { title: '直播新人券', activeMenu: '/home/live/market' }
	}],
	meta: {
		title: '直播',
		icon: 'el-icon-video-camera-solid'
	}
}, {
	path: '/home/data',
	component: Layout,
	redirect: '/home/data/performance',
	alwaysShow: true,
	children: [{
		path: 'performance',
		name: 'DataPerformance',
		component: () => import('@/views/liveplatform/data/performance'),
		meta: { title: '直播业绩' }
	}, {
		path: 'user-analysis',
		name: 'DataUserAnalysis',
		component: () => import('@/views/liveplatform/data/userAnalysis'),
		meta: { title: '流量分析' }
	}],
	meta: {
		title: '数据',
		icon: 'el-icon-s-marketing'
	}
}, {
	path: '*',
	redirect: '/home/dashboard',
	hidden: true
}]

export default liveplatformRouter
