// 卖家中心
import Layout from '@/layout/seller'
import subLayout from '@/layout/subSeller'
import teachingLayout from '@/layout/teachingLayout'


const liveplatformPath = window.location.protocol + "//liveplatform." + process.env.VUE_APP_BASE_URL
const sycmPath = window.location.protocol + "//sycm." + process.env.VUE_APP_BASE_URL
const materialPath = window.location.origin + "/home/decorate/material"

const sellerRouter = {
	path: '/home',
	component: Layout,
	redirect: '/home/index',
	children: [{
		path: 'index',
		name: 'homeIndex',
		component: () => import('@/views/seller/home/index'),
		meta: { title: '首页', icon: 'el-icon-s-home', menuAuth: true }
	}, {
		path: 'open-shop',
		name: 'openShop',
		hidden: true,
		component: () => import('@/views/seller/home/openShop'),
		meta: { title: '开店', menuAuth: true, activeMenu: '/home/index' }
	}, {
		path: 'merchant-user-info',
		name: 'merchantUserInfo',
		hidden: true,
		component: () => import('@/views/seller/home/merchantUserInfo'),
		meta: { title: '账号管理', menuAuth: true, activeMenu: '/home/index' }
	}, {
		path: 'goods',
		component: subLayout,
		redirect: '/home/goods/sell-manage/all',
		meta: { title: '商品', icon: 'el-icon-s-goods', menuAuth: true },
		children: [{
			path: 'sell-manage',
			redirect: '/home/goods/sell-manage/all',
			component: { render:(e) => e("router-view") },
			meta: { title: '商品管理', menuAuth: true },
			children: [{
				path: 'all',
				name: 'sellManage',
				component: () => import('@/views/seller/goods/sellManage'),
				meta: { title: '我的宝贝', menuAuth: true, activeMenu: '/home/goods' }
			}, {
				path: 'publish',
				name: 'sellPublish',
				component: () => import('@/views/seller/goods/publish'),
				meta: { title: '发布宝贝', menuAuth: true, activeMenu: '/home/goods' }
			}, {
				path: 'edit/:id',
				name: 'sellEdit',
				hidden: true,
				component: () => import('@/views/seller/goods/edit'),
				meta: { title: '发布宝贝', menuAuth: true, activeMenu: '/home/goods', subActiveMenu: '/home/goods/sell-manage/all' }
			}, {
				path: materialPath,
				name: 'sellMaterial',
				meta: { title: '图片空间', activeMenu: '/home/goods' }
			}]
		}]
	}, {
		path: 'order',
		component: subLayout,
		redirect: '/home/order/batch-consign/list',
		meta: { title: '交易', icon: 'el-icon-s-order' },
		children: [{
			path: 'trade-platform',
			component: { render:(e) => e("router-view") },
			redirect: '/home/order/trade-platform/sold',
			meta: { title: '订单管理' },
			children: [{
				path: 'sold',
				name: 'tradePlatform',
				component: () => import('@/views/seller/order/tradePlatform'),
				meta: { title: '已卖出的宝贝', activeMenu: '/home/order' }
			}, {
				path: 'detail/:id',
				name: 'orderDetail',
				hidden: true,
				component: () => import('@/views/seller/order/detail'),
				meta: { title: '已卖出的宝贝', activeMenu: '/home/order', subActiveMenu: '/home/order/trade-platform/sold' }
			}, {
				path: 'refund-list',
				name: 'refundList',
				component: () => import('@/views/seller/order/refundList'),
				meta: { title: '退款管理', activeMenu: '/home/order' }
			}, {
				path: 'refund-detail/:id',
				name: 'refundDetail',
				hidden: true,
				component: () => import('@/views/seller/order/refundDetail'),
				meta: { title: '退款详情', activeMenu: '/home/order', subActiveMenu: '/home/order/trade-platform/refund-list' }
			}, {
				path: 'comment',
				name: 'commentManage',
				component: () => import('@/views/seller/order/commentList'),
				meta: { title: '评价管理', activeMenu: '/home/order' }
			}]
		}, {
			path: 'batch-consign',
			component: { render:(e) => e("router-view") },
			redirect: '/home/order/batch-consign/list',
			meta: { title: '物流管理' },
			children: [{
				path: 'list',
				name: 'batchConsign',
				component: () => import('@/views/seller/order/batchConsign'),
				meta: { title: '发货', activeMenu: '/home/order' }
			}, {
				path: 'order/:type/post/:id',
				name: 'consignOrder',
				hidden: true,
				component: () => import('@/views/seller/order/consignOrder'),
				meta: { title: '发货中心', activeMenu: '/home/order', subActiveMenu: '/home/order/batch-consign/list' }
			}, {
				path: 'detail/:id',
				name: 'consignDetail',
				hidden: true,
				component: () => import('@/views/seller/order/consignDetail'),
				meta: { title: '物流详情', activeMenu: '/home/order', subActiveMenu: '/home/order/batch-consign/list' }
			}, {
				path: 'tools-group',
				name: 'logisPage',
				component: () => import('@/views/seller/logistics/logisPage'),
				meta: { title: '物流工具', activeMenu: '/home/order' }
			}, {
				path: 'logis-tools/:type?',
				name: 'logisTools',
				hidden: true,
				component: () => import('@/views/seller/logistics/logisTools'),
				meta: { title: '物流服务', activeMenu: '/home/order', subActiveMenu: '/home/order/batch-consign/tools-group' }
			}]
		}]
	}, {
		path: 'shop',
		component: subLayout,
		redirect: '/home/shop/manage/center',
		meta: { title: '店铺', icon: 'el-icon-s-shop', menuAuth: true },
		children: [{
			path: 'manage',
			component: { render:(e) => e("router-view") },
			redirect: '/home/shop/manage/center',
			meta: { title: '店铺管理', menuAuth: true },
			children: [{
				path: 'center',
				name: 'shopManage',
				component: () => import('@/views/seller/shop/shopManage'),
				meta: { title: '店铺信息', menuAuth: true, activeMenu: '/home/shop' }
			}, {
				path: 'tmc-material',
				name: 'tmcMaterial',
				component: () => import('@/views/seller/shop/tmcMaterial'),
				meta: { title: '店铺素材', activeMenu: '/home/shop' }
			}]
		}, {
			path: 'decorate',
			component: { render:(e) => e("router-view") },
			redirect: '/home/shop/decorate/pc',
			meta: { title: '店铺装修' },
			children: [{
				path: 'mobile',
				name: 'mobiledecorate',
				component: () => import('@/views/seller/shop/mobiledecorate'),
				meta: { title: '手机店铺装修', activeMenu: '/home/shop' }
			}, {
				path: 'pc',
				name: 'pcdecorate',
				component: () => import('@/views/seller/shop/pcdecorate'),
				meta: { title: 'PC店铺装修', activeMenu: '/home/shop' }
			}]
		}]
	}, {
		path: 'market',
		component: subLayout,
		redirect: '/home/market/tools',
		meta: {
			title: '营销',
			icon: 'el-icon-s-cooperation'
		},
		children: [{
			path: 'tools',
			name: 'marketingtools',
			component: () => import('@/views/seller/market/marketTools'),
			meta: { title: '营销工具', activeMenu: '/home/market' }
		}, {
			path: 'coupon-list',
			name: 'couponList',
			hidden: true,
			component: () => import('@/views/seller/market/couponList'),
			meta: { title: '商家营销中心', activeMenu: '/home/market', subActiveMenu: '/home/market/tools' }
		}, {
			path: 'coupon-create',
			name: 'couponCreate',
			hidden: true,
			component: () => import('@/views/seller/market/couponCreate'),
			meta: { title: '商家营销中心', activeMenu: '/home/market', subActiveMenu: '/home/market/tools' }
		}, {
			path: 'coupon-info/:type/:id',
			name: 'couponInfo',
			hidden: true,
			component: () => import('@/views/seller/market/couponInfo'),
			meta: { title: '商家营销中心', activeMenu: '/home/market', subActiveMenu: '/home/market/tools' }
		}]
	}, {
		path: liveplatformPath,
		// component: { render:(e) => e("router-view") },
		meta: {
			title: '直播',
			icon: 'el-icon-video-camera-solid'
		}
	}, {
		path: sycmPath,
		name: 'sycm',
		// component: { render:(e) => e("router-view") },
		meta: {
			title: '数据',
			icon: 'el-icon-s-data'
		}
	}, {
		path: 'teaching',
		component: teachingLayout,
		redirect: '/home/teaching/list',
		meta: { title: '教学', icon: 'el-icon-s-management' },
		children: [{
			path: 'list',
			name: 'teachingList',
			component: () => import('@/views/seller/assess/list'),
			meta: { title: '教学中心', activeMenu: '/home/teaching' }
		}, {
			path: 'info/:id?',
			name: 'teachingInfo',
			hidden: true,
			component: () => import('@/views/seller/assess/article'),
			meta: { title: '教学中心', activeMenu: '/home/teaching' }
		}]
	}, {
		path: 'assess',
		component: subLayout,
		redirect: '/home/assess/report',
		meta: { title: '测评', icon: 'el-icon-s-claim' },
		children: [{
			path: 'report',
			name: 'assessReport',
			component: () => import('@/views/seller/assess/report'),
			meta: { title: '个人成绩', activeMenu: '/home/assess' }
		}, {
			path: 'ranking',
			name: 'assessRanking',
			component: () => import('@/views/seller/assess/ranking'),
			meta: { title: '得分排名', activeMenu: '/home/assess' }
		}]
	}]
}
export default sellerRouter