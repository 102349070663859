<template>
	<el-container class="teacher-submain">
		<el-aside width="127px">
			<div class="submenu-title">{{submenuTitle}}</div>
			<el-menu
				class="teacher-submenu"
				:default-active="activeMenu"
			>
				<template v-for="item in routers">
					<app-link :key="item.path" :to="item.path">
						<el-menu-item :index="item.path" :disabled="isExternal(item.path)">
							<item :title="item.title" />
						</el-menu-item>
					</app-link>
				</template>
			</el-menu>
		</el-aside>
		<router-view :key="key" />
	</el-container>
</template>

<script>
	import path from 'path'
	import { isExternal } from '@/utils/validate'
	import teacherRouter from '@/router/modules/teacher'
	import AppLink from './components/Sidebar/Link'
	import Item from './components/Sidebar/Item'
	export default {
		components: { Item, AppLink },
		computed: {
			key() {
				return this.$route.path
			},
			activeMenu() {
				const route = this.$route
				const { meta, path } = route
				if (meta.subActiveMenu) {
					return meta.subActiveMenu
				}
				return path
			},
			submenuTitle() {
				const route = this.$route
				const { matched } = route
				return matched[1].meta.title
			}
		},
		data() {
			return {
				serviceRoutes: [],
				routers: [],
				basePath: '',
			}
		},
		created() {
			this.getMenuList()
		},
		methods: {
			getMenuList() {
				const route = this.$route
				const { matched } = route
				const routes = this.generateRoutes(teacherRouter.children, teacherRouter.path)
				let data = []
				routes.forEach(item => {
					if(matched[1].path === item.path) {
						data = item.children
					}
				})
				this.routers = data
			},
			generateRoutes(routes, basePath = '/') {
				const res = []
				for (let route of routes) {
					if (route.hidden) { continue }
					const data = {
						path: this.resolvePath(basePath, route.path),
						title: route.meta && route.meta.title
					}
					if (route.children) {
						data.children = this.generateRoutes(route.children, data.path)
					}
					res.push(data)
				}
				return res
			},
			resolvePath(basePath, routePath) {
				if (isExternal(routePath)) {
					return routePath
				}
				if (isExternal(basePath)) {
					return basePath
				}
				return path.resolve(basePath, routePath)
			},
			isExternal(path) {
				return isExternal(path)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.teacher-submain {
		.el-aside {
			height: calc(100vh - 75px);
			border-right: $--seller-subaside-border;
			background-color: $--seller-subaside-background;
		}
		.submenu-title {
			margin-bottom: 20px;
			padding-left: 20px;
			padding-top: 20px;
			font-size: 20px;
			line-height: 1em;
			color: #333;
		}
	}
	.teacher-submenu {
		padding: 0 9px;
		border-right: none;
		.el-menu-item {
			height: 42px;
			line-height: 42px;
			margin-bottom: 6px;
			padding: 0 9px!important;
			border-radius: 9px;
			&:hover {
				color: $--seller-submenu-hover-font;
				background-color: $--seller-submenu-hover-background;
			}
			&.is-active {
				color: $--seller-submenu-active-font;
				background-color: $--seller-submenu-active-background;
			}
		}
	}
</style>