<template>
	<el-container class="uu-live">
		<el-header height="77px">
			<a href="/" class="uu-logo"><img src="~@/assets/images/logo-292-80.png" alt=""></a>
			<ul class="uu-headbar">
				<li>
					<el-button type="livebtn" @click="goLive" size="mini" round>{{$t('liveplatform.header.gateway')}}</el-button>
				</li>
				<li>
					<el-dropdown>
						<span class="el-dropdown-link">
							<img :src="userInfo.storeLogo" @error="imageLoadError" alt="">
							<span>{{userInfo.storeName?userInfo.storeName:userInfo.userName}}</span>
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="uu-dropdown">
							<el-dropdown-item @click.native="handleLogout"><i class="el-icon-switch-button"></i>
							{{$t('liveplatform.header.logout')}}
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</li>
			</ul>
		</el-header>
		<el-container class="uu-container">
			<el-aside width="180px">
				<sidebar class="uu-menu" :is-live="true" :menu-bg="menuBg" :menu-text="menuText" :menu-active-text="menuTextActive" />
				<div class="menu-bottom">
					<a :href="$liveBasePath+'/upload'" class="down-btn" target="_blank">
						<i class="el-icon-monitor"></i>
						<div class="btn-box">
							<h3>{{ $t('liveplatform.header.pcTitle') }}</h3>
							<p>{{ $t('liveplatform.header.pcSupport') }}</p>
						</div>
						<i class="el-icon-download"></i>
					</a>
				</div>
			</el-aside>
			<el-scrollbar class="uu-main">
				<router-view :key="key" />
			</el-scrollbar>
		</el-container>
	</el-container>
</template>

<script>
	import { mapGetters } from 'vuex'
	import Sidebar from './components/Sidebar'
	import theme from "@/assets/css/mixin.scss"
	
	export default {
		components: { Sidebar },
		computed: {
			...mapGetters([
				'userInfo'
			]),
			key() {
				return this.$route.path
			}
		},
		data() {
			return {
				menuBg: theme.liveMenuBg,
				menuText: theme.liveMenuText,
				menuTextActive: theme.liveMenuTextActive
			}
		},
		methods: {
			imageLoadError(e) {
				e.target.src = this.$imageErrorUser;
				e.target.style.objectFit = 'fill';
			},
			goLive() {
				window.location.href = this.$liveBasePath
			},
			async handleLogout(){
				await this.$store.dispatch('user/logout')
				window.location.href = this.$loginBasePath + '?jump_url=' + window.location.href
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-live {
		height: 100%;
		background-color: $--live-background-color;
		::v-deep .el-aside {
			height: calc(100% - 24px);
			margin-left: 18px;
			border-radius: 6px;
			background-color: $--live-background-default;
		}
	}
	.uu-logo {
		float: left;
		padding: 20px 0 19px;
		height: 77px;
		box-sizing: border-box;
	
		img {
			max-height: 100%;
		}
	}
	.uu-headbar {
		float: right;
		padding: 20px 0;
		height: 37px;
		line-height: 37px;
		white-space: nowrap;
	
		li {
			display: inline-block;
			margin-left: 16px;
	
			.el-button--livebtn {
				color: #FFFFFF;
				padding: 5px 15px;
				background-color: $--live-gray-white-16;
				border-color: $--live-gray-white-16;
			}
	
			::v-deep .el-dropdown {
				.el-dropdown-link {
					color: $--live-text-color-2;
					cursor: pointer;
	
					img {
						width: 35px;
						height: 35px;
						border-radius: 50%;
						object-fit: cover;
						margin-right: 8px;
					}
				}
			}
		}
	}
	.uu-dropdown {
		background-color: $--live-gray-black-23;
		border-color: $--live-gray-black-23;
		box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .48), 0 6px 16px 0 rgba(0, 0, 0, .32), 0 9px 28px 8px rgba(0, 0, 0, .2);

		::v-deep .el-dropdown-menu__item {
			color: $--live-text-color-2;
			min-width: 100px;
			&:focus,&:not(.is-disabled):hover {
				color: $--live-text-color-2;
				background-color: rgba(255, 255, 255, .1);
			}
		}
		::v-deep .popper__arrow {
			border-bottom-color: $--live-gray-black-23;
			&:after {
				border-bottom-color: $--live-gray-black-23;
			}
		}
	}
	.uu-container {
		height: calc(100% - 77px);
		.menu-bottom {
			padding: 10px;
			.down-btn {
				height: 50px;
				padding: 7px;
				border-radius: 6px;
				cursor: pointer;
				color: #FFFFFF;
				display: flex;
				align-items: center;
				justify-content: space-between;
				box-sizing: border-box;
				background-color: rgba(255,255,255,.3);
				[class^=el-icon-] {
					font-size: 20px;
				}
				.btn-box {
					line-height: 20px;
					h3 {
						font-size: 12px;
						font-weight: bold;
					}
					p {
						font-size: 12px;
						color: rgba(255,255,255,.6);
					}
				}
			}
		}
		
		.uu-menu {
			height: calc(100% - 100px);
			box-sizing: border-box;
			::v-deep .el-scrollbar {
				.el-scrollbar__wrap {
					overflow-x: hidden;
					margin-bottom: 0!important;
				}
				.el-scrollbar__view {
					padding: 10px;
				}
			}
			::v-deep .el-menu {
				.el-menu-item,.el-submenu__title {
					height: 30px;
					line-height: 30px;
					border-radius: 6px;
					i {
						color: inherit;
					}
				}
				.el-menu-item {
					margin-top: 4px;
					margin-bottom: 8px;
					&:focus,&:hover {
						background-color: rgba(255, 255, 255, .1)!important;
					}
					&.is-active {
						background-color: rgba(255,255,255,.3)!important;
					}
				}
				.el-submenu__title {
					margin-top: 20px;
					margin-bottom: 4px;
					&:focus,&:hover {
						background-color: rgba(255, 255, 255, .1)!important;
					}
				}
				.el-submenu {
					.el-menu-item {
						min-width: 100%;
						padding-right: 15px;
					}
				}
			}
		}
		::v-deep .uu-main {
			flex: 1;
			>.el-scrollbar__wrap {
				height: calc(100% + 17px);
				>.el-scrollbar__view {
					padding: 0 18px;
					min-width: 1024px;
				}
			}
		}
	}
</style>
<style lang="scss">
	.live-date-picker {
		color: $--live-text-color-2;
		border-color: $--live-gray-white-4;
		background-color: $--live-gray-black-23;
		box-shadow: 0 2px 12px 0 rgb(48,48,48,.1);
		.popper__arrow {
			border-bottom-color: $--live-gray-white-4!important;
			&:after {
				border-bottom-color: $--live-gray-black-23!important;
			}
		}
		.el-date-picker__time-header {
			border-bottom-color: $--live-gray-white-4;
		}
		.el-time-panel {
			border-color: $--live-gray-white-4;
			background-color: $--live-gray-black-23;
			box-shadow: 0 2px 12px 0 rgb(48,48,48,.1);
			.el-time-spinner {
				.el-time-spinner__item {
					color: $--live-text-color-2;
					&:hover:not(.disabled):not(.active) {
						background-color: $--live-gray-white-4;
					}
					&.disabled {
						color: $--live-gray-white-20;
					}
					&.active:not(.disabled) {
						color: $--live-picker-color;
						/* background-color: #2c111a; */
					}
				}
			}
			.el-time-panel__content {
				&:after,&:before {
					border-top-color: $--live-gray-white-4;
					border-bottom-color: $--live-gray-white-4;
				}
			}
			.el-time-panel__footer {
				border-top-color: $--live-gray-white-4;
				.el-time-panel__btn {
					&.cancel {
						color: $--live-text-color-2;
					}
					&.confirm {
						color: $--live-picker-color;
						&:focus,&:hover {
							color: $--live-button-primary-hover-font;
						}
					}
				}
			}
		}
		.el-input {
			.el-input__inner {
				color: $--live-text-color-2;
				border-color: transparent;
				background-color: $--live-input-background;
			}
		}
		.el-date-table {
			th {
				color: $--live-text-color-2;
				border-bottom-color: $--live-gray-white-4;
			}
			td {
				span {
					border-radius: 6px;
				}
				&.disabled {
					div {
						color: $--live-gray-white-20;
						background-color: $--live-gray-white-4;
					}
				}
				&.next-month,&.prev-month {
					color: $--live-gray-white-20;
				}
				&.available {
					&:hover {
						span {
							color: $--live-text-color-2;
							background-color: $--live-gray-white-4;
						}
					}
				}
				&.today {
					span {
						color: $--live-text-color-2;
						background-color: transparent;
						box-shadow: 0 0 0 1px $--live-picker-color inset;
					}
					&:hover {
						span {
							background-color: $--live-gray-white-4;
						}
					}
				}
				&.current:not(.disabled) {
					span {
						color: #FFFFFF;
						background-color: $--live-picker-color;
					}
				}
			}
		}
		.el-year-table {
			td {
				.cell {
					color: $--live-text-color-2;
				}
				&.disabled {
					.cell {
						color: $--live-gray-white-20;
						background-color: $--live-gray-white-4;
					}
				}
				&.today {
					.cell {
						color: $--live-picker-color;
					}
				}
				&.current:not(.disabled) {
					.cell {
						color: $--live-picker-color;
					}
				}
			}
		}
		.el-month-table {
			td {
				&:hover {
					.cell {
						color: $--live-link-hover-1;
					}
				}
			}
		}
		.el-date-picker__header {
			.el-date-picker__header-label {
				color: $--live-text-color-2;
				:hover {
					color: $--live-link-hover-1;
				}
			}
			.el-picker-panel__icon-btn {
				color: $--live-text-color-2;
				:hover {
					color: $--live-link-hover-1;
				}
			}
		}
		.el-picker-panel__body {
			.el-date-picker__header--bordered {
				border-bottom-color: $--live-gray-white-4;
			}
		}
		.el-picker-panel__footer {
			background-color: $--live-gray-black-23;
			border-top-color: $--live-gray-white-4;
			.el-button--text {
				color: $--live-button-primary-font;
				&:hover {
					color: $--live-button-primary-hover-font;
				}
			}
			.el-button--default {
				color: #FFFFFF;
				background-color: $--live-button-primary-background;
				border-color: $--live-button-primary-border;
				&:focus,&:hover {
					background-color: $--live-button-primary-hover-background;
					border-color: $--live-button-primary-hover-border;
				}
			}
		}
	}
	.live-popover {
		background-color: $--live-gray-black-23;
		border-color: $--live-gray-black-23;
		box-shadow: 0 3px 6px -4px rgba(0, 0, 0, .48), 0 6px 16px 0 rgba(0, 0, 0, .32), 0 9px 28px 8px rgba(0, 0, 0, .2);
		&[x-placement^=top] {
			.popper__arrow {
				border-top-color: $--live-gray-black-23 !important;
				&:after {
					border-top-color: $--live-gray-black-23 !important;
				}
			}
		}
		&[x-placement^=bottom] {
			.popper__arrow {
				border-bottom-color: $--live-gray-black-23 !important;
				&:after {
					border-bottom-color: $--live-gray-black-23 !important;
				}
			}
		}
	}
	.live-popover-tip {
		color: #FFFFFF;
		background-color: $--live-info-color;
		border-color: $--live-gray-black-16;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12);
		&[x-placement^=top] {
			.popper__arrow {
				border-top-color: $--live-gray-black-16 !important;
				&:after {
					border-top-color: $--live-info-color !important;
				}
			}
		}
		&[x-placement^=bottom] {
			.popper__arrow {
				border-bottom-color: $--live-gray-black-16 !important;
				&:after {
					border-bottom-color: $--live-info-color !important;
				}
			}
		}
	}
	.live-dialog {
		.el-dialog {
			background-color: $--live-background-default;
			.el-dialog__header {
				padding: 16px 20px;
				border-bottom: 1px solid $--live-gray-white-4;
				.el-dialog__title {
					color: #FFFFFF;
					font-size: 16px;
				}
				.el-dialog__headerbtn {
					.el-dialog__close {
						color: $--live-link-color-4;
					}
					&:hover {
						.el-dialog__close {
							color: #FFFFFF;
						}
					}
				}
			}
			.el-dialog__body {
				padding: 20px;
			}
			.el-dialog__footer {
				border-top: 1px solid $--live-gray-white-4;
				padding: 10px 20px;
			}
		}
		.dialog-footer {
			.el-button--default {
				color: #FFFFFF;
				border-color: #666666;
				background-color: transparent;
				&:hover,&:focus {
					color: $--live-button-default-hover-font;
					border-color: $--live-button-default-hover-border;
				}
			}
			.el-button--danger {
				color: #FFFFFF;
				border-color: $--live-button-primary-border;
				background-color: $--live-button-primary-background;
				&:hover,&:focus{
					border-color: $--live-button-primary-hover-border;
					background-color: $--live-button-primary-hover-background;
				}
			}
		}
	}
</style>