<template>
	<div class="home-foot">
		<div class="msg1">{{ $t('login.footer.title') }}</div>
		<div class="msg2">{{ $t('login.footer.copyright') }}</div>
		<div class="msg3">{{ $t('login.footer.ICP') }}</div>
	</div>
</template>

<script>
	export default {
		name: 'uuFooter'
	}
</script>

<style lang="scss" scoped>
	.home-foot {
		width: 1140px;
    margin: 0 auto;
    padding: 55px 0 20px;
    line-height: 20px;
		.msg1 {
			padding: 12px;
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
		}
		.msg2 {
			padding: 12px 0 7px 12px;
			color: #aaa;
		}
		.msg3 {
			padding-left: 12px;
			color: #666;
		}
	}
</style>