import { fetchArticleMenu } from "@/api/seller/assess"

const state = {
    menu: []
}

const mutations = {
    SET_MENU: (state, menu) => {
        state.menu = menu
    }
}

const actions = {
    getMenu({ commit }) {
        return new Promise((resolve, reject) => {
            fetchArticleMenu({}).then(response => {
                commit('SET_MENU', response.data)
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
