const liveplatform = {
	header: {
		gateway: '直播门户',
		logout: '退出',
		pcTitle: 'PC端直播工具',
		pcSupport: '支持windows'
	},
	home: {
		id: 'ID',
		fans: '粉丝',
		createBtn: '创建直播',
		pageTitle1: '直播间数据',
		'pageText1-1': '昨日浏览次数',
		'pageText1-2': '昨日增粉数',
		'pageText1-3': '昨日种草成交',
		pageTitle2: '我的直播间',
		pageTitle3: '我的服务',
		'pageName3-1': '直播推广',
		'pageText3-1': '主播合作推广',
		'pageName3-2': '直播活动',
		'pageText3-2': '平台活动提报',
		'pageName3-3': '货品报名',
		'pageText3-3': '货品活动提报',
		'pageName3-4': '我的店铺',
		'pageText3-4': '店铺管理平台',
		'pageName3-5': '客服大厅',
		'pageText3-5': '问题咨询平台',
		more: '查看更多',
	},
	list: {
		title: '直播管理',
		createBtn: '创建直播',
		tagText1: '预告',
		tagText2: '直播中',
		tagText3: '回放',
		visitor: '观看人数',
		settime: '直播时间',
		liveID: '直播ID',
		expired: '已过期',
		infoBtn: '直播详情',
		openBtn: '正式开播',
		seePlaybackBtn: '查看回放',
		playbackBtn: '回放',
		spreadBtn: '直播推广',
		dataBtn: '数据详情',
		deleteBtn: '删除',
		shareBtn: '直播分享码',
		copyBtn: '复制链接',
		vertical: '竖屏',
		horizontal: '横屏',
		banned: '禁播'
	},
	live: {
		title: '创建直播',
		roomJson: [
			{ label: '普通直播间', value: 'common'}
		],
		screenJson: [
			{ label: '竖屏', value: 'vertical'},
			{ label: '横屏', value: 'horizontal'}
		],
		formItem1: '封面图',
		'formText1-1': '上传图片',
		'formText1-2': '2mb以内',
		formItem2: '直播时间',
		'formText3-1': '当前位置',
		'formText3-2': '刷新定位',
		'formText3-3': '手动选择',
		formItem4: '频道栏目',
		'formText4-1': '选择频道',
		formItem5: '简介',
		formItem6: '添加预告视频',
		'formText6-1': '上传视频',
		'formText6-2': '100kb-20mb',
		formItem7: '直播宝贝',
		'formText7-1': '添加宝贝',
		reupload: '重新上传',
		notRequired: '非必填项',
		tips1: '开播时自动把预告商品发布到直播间',
		tips2: '允许用户预约宝贝讲解提醒',
		tips3: '仅投放至私域',
		tips4: '店铺商品同步直播',
		explain: '说明',
		submitBtn: '创建直播',
		dialogTitle1: '上传',
		dialogTitle2: '添加标签',
		dialogTitle3: '宝贝',
		dialogConfirm1: '确 认',
		dialogConfirm2: '确 定',
		dialogCancel: '取 消',
		dialogCheckAll: '全选'
	},
	data: {
		renewText1: '于',
		renewText2: '更新',
		tips1: '近期直播表现平稳，做好准备蓄势待发吧',
		tabPane1: '直播间业绩表现',
		tabPane2: '我的直播列表',
		pageTitle1: '直播间大盘',
		pageSubTitle1: '统计包含全部商品',
		pageTitle2: '开播场次',
		pageTitle3: '直播订单明细',
		pageSubTitle3: '包含全部商品订单',
		pageTips3: '仅展示近90天支付/确认收货订单，支持查看/下载5千条数据',
		pageTitle4: '直播分场次效果',
		pageSubTitle4: '统计包含全部商品',
		pageTitle5: '直播分商品效果',
		pageSubTitle5: '仅展示宝贝口袋内商品',
		more: '右滑查看更多',
		day7: '7天',
		day15: '15天',
		day30: '30天',
		chart: '图表',
		table: '表格',
		download: '下载',
		timeTypeOption1: '支付时间',
		timeTypeOption2: '确认收货时间',
		checkAll: '全选',
		uncheckAll: '全不选',
		tabsName1: '实时下播数据',
		tabsTips1: '已结束的直播，展示下播定格数据；未结束直播场次为当前实时数据',
		tabsName2: '含回放累计数据',
		tabsTips2: '次日12点前更新前一日开播场次数据，持续追踪开播后15日内引导成交数据',
		tips2: '开播时间',
		tips3: '支持查看近90天实时下播数据',
		analyseText1: '近7日直播间访问用户数低于本专业主播平均水平',
		analyseTitle: '直播间观看人数',
		analyseName1: '累计粉丝数',
		analyseTips1: '直播账号累计关注粉丝数（包含直播间、主页等关注入口)',
		analyseName2: '昨日粉丝回访率',
		analyseTips2: '直播间访问粉丝数/前一日账号内累计粉丝数',
		analyseName3: '昨日直播间新增粉丝数',
		analyseTips3: '所选时间范围内，用户在直播间内的新增关注粉丝数',
	},
	dataJson1: [{
			label: '开播场次',
			value: 'open_times',
			tip: '开播场次: 所选时间范围内，成功开播场次数（若直播跨天，单日维度均计为开播)'
		},
		{
			label: '当日观看人数',
			value: 'visitors',
			tip: '当日观看人数: 所选时间范围内，直播间累计观看人数（含直播回放）'
		},
		{
			label: '直播间新增粉丝数',
			value: 'new_fans',
			tip: '直播间新增粉丝数: 所选时间范围内，直播间内引导新增关注粉丝数'
		},
		{
			label: '当日成交人数',
			value: 'deal_num',
			tip: '当日成交人数: 所选时间范围内，通过点击直播间商品后15天内引导支付成功的用户数'
		},
		{
			label: '当日成交金额',
			value: 'deal_amount',
			tip: '当日成交金额: 所选时间范围内，通过点击直播间商品后15天内引导支付成功的总金额'
		},
		{
			label: '当日观看次数',
			value: 'view_count',
			tip: '当日观看次数: 所选时间范围内，直播间页面累计观看次数'
		},
		{
			label: '粉丝回访率',
			value: 'fans_visit_rate',
			tip: '粉丝回访率: 所选时间范围内，直播间访问粉丝数/前一日累计粉丝数'
		},
		{
			label: '商品点击人数',
			value: 'goods_click_uv',
			tip: '商品点击人数: 所选时间范围内，用户点击宝贝进入详情页的人数'
		},
		{
			label: '商品点击次数',
			value: 'goods_click_pv',
			tip: '商品点击次数: 所选时间范围内，用户点击宝贝进入详情页的次数'
		},
		{
			label: '开播时长（分钟）',
			value: 'live_times',
			tip: '所选时间范围内，开播场次的开始至结束时间长短'
		}
	],
	dataJson2: [{
			title: '流量',
			options: [{
					label: '观看次数',
					value: 'view_count'
				},
				{
					label: '观看人数',
					value: 'visitor_count'
				}
			]
		},
		{
			title: '转粉',
			options: [{
				label: '新增粉丝数',
				value: 'new_fans'
			}]
		},
		{
			title: '引导',
			options: [{
					label: '商品点击人数',
					value: 'goods_click_uv'
				},
				{
					label: '商品点击次数',
					value: 'goods_click_pv'
				},
				{
					label: '商品点击率',
					value: 'goods_click_rate'
				}
			]
		},
		{
			title: '成交',
			options: [{
					label: '引导成交转化率',
					value: 'turnover_rate'
				},
				{
					label: '引导成交人数',
					value: 'trans_count'
				},
				{
					label: '引导成交金额',
					value: 'trans_amount'
				}
			]
		}
	],
	dataJson3: [{
			label: '商品点击次数',
			value: 'goods_click_pv'
		},
		{
			label: '商品点击人数',
			value: 'goods_click_uv'
		},
		{
			label: '引导成交件数',
			value: 'goods_sales_num'
		},
		{
			label: '引导成交金额',
			value: 'trans_amount'
		}
	],
	tableHead: {
		censusDay: '统计日期',
		openTimes: '开播场次',
		visitors: '观看人数',
		visitorsDay: '当日观看人数',
		newFans: '新增粉丝数',
		newFansRoom: '直播间新增粉丝数',
		dealNumDay: '当日成交人数',
		dealAmountDay: '当日成交金额',
		viewCount: '观看次数',
		viewCountDay: '当日观看次数',
		fansVisitRate: '粉丝回访率',
		goodsClickUv: '商品点击人数',
		goodsClickPv: '商品点击次数',
		goodsClickRate: '商品点击率',
		guidedDealRate: '引导成交转化率',
		guidedDealNum: '引导成交人数',
		guidedDealPiece: '引导成交件数',
		guidedDealAmount: '引导成交金额',
		liveTimes: '开播时长（分钟）',
		liveTitle: '直播标题',
		openTime: '开播时间',
		timesID: '场次ID',
		goodsID: '商品ID',
		goodsTitle: '商品标题',
		goodsCate: '商品一级类目',
		goodsTitleImage: '商品主图/标题',
		orderParent: '父订单',
		orderSub: '子订单',
		orderTime: '下单时间',
		payTime: '支付时间',
		payAmount: '支付金额',
		userName: '会员名',
		couponName: '名称/渠道',
		couponMoney: '面额',
		couponQuota: '门槛',
		couponGiveTime: '可发放时间',
		couponUseTime: '使用时间',
		couponLimitGive: '限领',
		couponIssuableNum: '当前可发放量',
		couponGiveNum: '发行量'
	},
	decoration: {
		title: '直播间装修',
		pageTitle1: '模版装修',
		'pageItem1-1': '主播信息卡',
		'pageItem1-2': '可配置主播信息、营销信息、预告信息、营销素材，或摆上您的镇店之宝',
		useBtn: '立即使用'
	},
	coupon: {
		pageName1: '配置新人券',
		pageTips1: '直播间新人专享，帮助直播间承接新人流量和鼓励二次转化',
		pageText1: '新人进入直播间获得权益',
		pageName2: '配置购后券',
		pageTips2: '直播间配置购后券,用户下单后发券,大额券帮助直播间二次成交',
		pageText2: '直播间首单转化后获得权益',
		selectBtn: '选择权益',
		seeBtn: '查看优惠券发放情况',
		effectBtn: '配置生效',
		deleteBtn: '删除',
		dialogTitle: '权益设置',
		tabPane1: '优惠券',
		couponTypeJson: [{
			label: '店铺券',
			value: '1'
		}, {
			label: '商品券',
			value: '2'
		}],
		createBtn: '创建优惠券',
		unlimited: '不限',
		confirmBtn: '确定',
		cancelBtn: '取消'
	},
	market: {
		title: '直播互动',
		pageTitle1: '拉新涨粉',
		pageTitle2: '时长提升',
		pageTitle3: '商品转化',
		'pageItem1-1': '新人券/购后券',
		'pageItem1-2': '新人券：基于新人来访配置专属优惠券，拉动新人的转粉并提升直播粘性用户；购后券：在用户下单后发券，帮助直播间二次成交',
		useBtn: '立即使用',
		setBenefits: '权益配置'
	},
	infoCard: {
		pageTitle: '我的信息卡',
		createBtn: '新建',
		pageAddTitle: '我的信息卡',
		pageEditTitle: '我的信息卡',
		deleteBtn: '删除',
		paneName1: '编辑组件',
		paneName2: '上传图片',
		formItem1: '信息卡名称:',
		formItem2: '选择你要添加的模块类型，并编辑内容:',
		formItem3: '标题',
		formItem4: '主播头像',
		'formText4-1': '图片尺寸为750x750',
		formItem5: '主播昵称',
		formItem6: '尺码信息',
		formItem7: '营销信息',
		formItem8: '预告时间',
		formItem9: '预告内容',
		formItem10: '上传素材',
		'formText10-1': '图片尺寸为190x190',
		formItem11: '宝贝图片',
		'formText11-1': '公告点击跳转目前仅支持本店商品，其他店铺商品后续将会支持，商品非必选，选择后用户点击公告将支持跳转对应商品',
		formItem12: '利益点',
		formItem13: '价格信息',
		formItem14: '信息卡图片',
		'formText14-1': '请上传信息卡图片，指定宽度380px，高度不小于840px，',
		'formText14-2': '图片高度超出840px的部分可滚动查看',
		groupItem1: '主播信息',
		groupItem2: '营销类信息',
		groupItem3: '预告信息',
		groupItem4: '营销素材',
		groupItem5: '镇店之宝',
		upload: '上传图片',
		reupload: '重新上传',
		saveBtn: '保 存',
		submitBtn: '提 交',
		dialogTitle: '上传',
		dialogConfirm: '确 认',
		dialogCancel: '取 消',
	},
	liveInfo: {
		status: '直播中',
		endBtn: '结束直播',
		openBtn: '打开推流工具',
		paneName1: '实时表现',
		'paneText1-1': '在线人数',
		'paneText1-2': '新增粉丝',
		'paneText1-3': '观看次数',
		'paneText1-4': '成交金额',
		paneName2: '宝贝列表',
		chartTitle: '在线用户'
	},
	liveEdit: {
		pageTitle: '直播详情',
		status: '预告',
		time: '开播时间',
		column: '直播栏目',
		position: '直播位置',
		dialogTitle1: '修改直播信息',
		dialogTitle2: '上传',
		upload: '上传图片',
		uploadVideo: '上传视频',
		reupload: '重新上传',
		coverTips: '2mb以内',
		videoTips: '非必填',
		dialogConfirm: '确 认',
		dialogCancel: '取 消',
	},
	livePlayback: {
		pageTitle: '直播回放',
		paneText1: '观看次数',
		paneText2: '成交人数',
		paneText3: '直播成交金额',
		paneText4: '最高在线人数',
		paneText5: '观看时长(秒)',
		paneText6: '新增粉丝数',
		tips1: '若直播画面存在断流，则回放画面无法正常查看',
		noPicture: '无回放画面',
		explainBtn: '直播讲解',
		largeDataBtn: '数据大屏',
		chartTitle: '在线用户'
	},
	pagination: {
		prevText: '上一页',
		nextText: '下一页',
	},
	validate: {
		liveTime: '直播时间不能为空!',
		livePosition: '直播位置不能为空!',
		liveChannel: '请选择频道栏目!',
		liveCover: '直播封面不能空!',
		liveTitle: '直播标题不能空!',
		cardName: '请输入信息卡名称',
		cardModule: '请选择添加模块类型!',
		cardAvatar: '必须上传主播头像',
		cardCover: '必须上传信息卡图片',
		cardNotNull: '不能为空',
		cardMaterial: '必须上传素材',
	},
	placeholder: {
		date: '选择日期',
		startDate: '开始日期',
		endDate: '结束日期',
		goodsTitleID: '请输入商品标题/ID',
		separator: '至',
		selectTimes: '选择场次',
		all: '全部',
		liveTitle:'请在这里输入标题',
		liveStartTime: '直播开始时间',
		livePosition: '搜索附近位置',
		liveRegion: '请选择直播位置',
		liveIntro: '请在这里输入直播简介',
		cardName: '请输入信息卡名称，不超过 8 个字符',
		cardGroupName: '不超过4个汉字',
		cardGroupText: '请填写内容',
	},
	echarts: {
		legendData1: ['我', '同级同类目均值'],
		indicator1: [{
				text: '开播'
			},
			{
				text: '成交'
			},
			{
				text: '转粉'
			},
			{
				text: '观看'
			},
			{
				text: '流量'
			}
		],
		legendData2: ["非粉丝访问用户数", "粉丝访问用户数"]
	},
	successMsg: {
		deploy: '配置生效成功!',
		delete: '删除成功!',
		create: '创建成功!',
		copy: '复制成功!',
		add: '添加成功!',
		edit: '编辑成功!',
		save: '保存成功!',
		end: '结束成功!',
		paly: '开播成功!'
	},
	errorMsg: {
		exportTime: '选择导出时间段!',
		selectBenefits: '请选择权益!',
		addBenefits: '请先添加权益!',
		imgFileType: '上传图片只能是 JPG/PNG 格式!',
		imgFileSize2M: '上传图片大小不能超过 2MB!',
		imgFileSize5M: '上传图片大小不能超过 5MB!',
		videoFileType: '上传格式错误!',
		videoFileSize20M: '上传视频大小不能超过 20MB!',
		upload: '上传失败!',
		liveColumn: '请先选择直播栏目哦',
		cardChange: '请按顺序选择模块进行编辑并保存',
		groupMinLength: '左侧模块不得少于两个',
		groupFormWrite: '部分表单项未按要求填写，请检查后重新提交',
		submit: '提交失败!',
		noAudio: '找不到音频设备!',
		selectGoods: '请选择商品!'
	},
	cancelMsg: {
		default: '已取消!',
		delete: '已取消删除!',
		play: '已取消开播!',
		end: '已取消结束!',
		add: '已取消添加!'
	},
	alertMsg: {
		liveTool: '请先下载推流端推流'
	},
	confirmMsg: {
		title: '提示',
		benefitsText: '确定要删除当前权益吗？',
		liveDeleteText: '此操作将永久删除该数据, 是否继续?',
		playText: '确认开播么?',
		playSuccessTitle: '开播成功',
		playSuccessText: '可以选择进入直播详情或直接下载推流端推流',
		deleteText: '确认删除？',
		openToolText: '打开推流工具将关闭预览画面，是否继续？',
		endLiveTitle: '结束直播',
		endLiveText: '确定要结束当前直播么？',
		confirmBtn: '确定',
		continueBtn: '继续',
		cancelBtn: '取消',
		confirmJoinBtn: '进入直播详情',
		cancelDownBtn: '下载推流端推流',
		addGoodsText: '确认添加商品？'
	},
	export: {
		text1: '直播间大盘数据',
		text2: '直播间成交订单明细',
		text3: '直播分场次效果',
		text4: '商品分场次效果'
	},
	screen: {
		couponType: '优惠券类型',
	},
	unit: {
		price: '元',
		day: '天'
	},
	empty: {
		data: '空数据',
		noGoods: '暂无宝贝'
	},
	marsText: '在火星'
}
export default liveplatform
