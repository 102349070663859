<template>
	<div class="head-nav">
		<div class="nav-wrap">
			<ul>
				<li v-if="userInfo.userId > 0">
					<el-popover
						placement="top-start"
						trigger="hover">
						<div slot="reference" class="nav-hd">
							<a href="javascript:void(0);" class="nav-link">{{userInfo.userName}}</a>
							<i class="el-icon-arrow-down"></i>
						</div>
						<div class="popover-user">
							<img class="item-pic" :src="userInfo.portrait" alt="">
							<div class="item-block">
								<ul class="item-actions">
									<li><a href="javascript:void(0);">账号管理</a></li>
									<li class="pipe">|</li>
									<li><a href="javascript:void(0);" @click="handleLogout">退出</a></li>
								</ul>
							</div>
						</div>
					</el-popover>
				</li>
				<li v-else>
					<a :href="$loginBasePath">亲，请登录</a>
				</li>
			</ul>
			<ul>
				<li v-if="$route.name !== 'homeIndex'">
					<div class="nav-hd">
						<a class="nav-link" href="/">首页</a>
					</div>
				</li>
				<li>
					<el-dropdown>
						<div class="nav-hd">
							<a href="JavaScript:void(0);" class="nav-link">个人中心</a>
							<i class="el-icon-arrow-down"></i>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>已买到的宝贝</el-dropdown-item>
							<el-dropdown-item>我的足迹</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</li>
				<li>
					<div class="nav-hd">
						<a href="JavaScript:void(0);" class="nav-link"><i class="nav-icon el-icon-shopping-cart-1"></i>购物车</a>
					</div>
				</li>
				<li>
					<el-dropdown>
						<div class="nav-hd">
							<a href="JavaScript:void(0);" class="nav-link"><i class="nav-icon el-icon-star-on"></i>收藏夹</a>
							<i class="el-icon-arrow-down"></i>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>收藏的宝贝</el-dropdown-item>
							<el-dropdown-item>收藏的店铺</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</li>
				<li class="pipe">|</li>
				<li>
					<el-dropdown>
						<div class="nav-hd">
							<a href="JavaScript:void(0);" class="nav-link">卖家中心</a>
							<i class="el-icon-arrow-down"></i>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>已卖出的宝贝</el-dropdown-item>
							<el-dropdown-item>出售中的宝贝</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</li>
				<li>
					<el-dropdown>
						<div class="nav-hd">
							<a href="JavaScript:void(0);" class="nav-link">联系客服</a>
							<i class="el-icon-arrow-down"></i>
						</div>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item>消费者客服</el-dropdown-item>
							<el-dropdown-item>卖家客服</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import { mapGetters } from 'vuex'
	
	export default {
		name: 'uuHeader',
		computed: {
			...mapGetters([
				'userInfo'
			])
		},
		methods: {
			async handleLogout(){
				await this.$store.dispatch('user/logout')
				window.location.href = this.$loginBasePath + '?jump_url=' + window.location.href
			}
		}
	}
</script>

<style lang="scss" scoped>
	.head-nav {
		height: 35px;
		background-color: #f5f5f5;
		border-bottom: 1px solid #eee;
		.nav-wrap {
			width: 1190px;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			ul {
				li {
					display: inline-block;
					vertical-align: middle;
					&.pipe {
						color: #ddd;
						padding: 0 5px;
						font-style: normal;
					}
					.nav-hd {
						padding: 0 6px;
						height: 35px;
						line-height: 35px;
						display: block;
						cursor: pointer;
						font-size: 12px;
					}
					.nav-link {
						color: #6C6C6C;
						&:hover {
							color: #f40;
						}
					}
					.nav-icon {
						margin-right: 5px;
						&.el-icon-shopping-cart-1 {
							color: #f40;
						}
						&.el-icon-star-on {
							font-size: 14px;
						}
					}
					.el-icon-arrow-down {
						margin-left: 4px;
					}
				}
			}
		}
	}
	.popover-user {
		display: flex;
		.item-pic {
			width: 52px;
			height: 52px;
			border-radius: 50%;
			object-fit: cover;
		}
		.item-block {
			margin-left: 0.32rem;
		}
		.item-actions {
			text-align: right;
			li {
				display: inline-block;
				&.pipe {
					color: #ddd;
					padding: 0 5px;
					font-style: normal;
				}
				a {
					color: #6C6C6C;
					&:hover {
						color: #f40;
					}
				}
			}
		}
	}
</style>