const teacher = {
	base: {
		student: '学生',
		account: '账号',
		seller: '卖家中心',
		accountInfo: '账号信息',
		shopInfo: '店铺信息',
		logout: '退出当前账号'
	},
	order: {
		paneTitle1: '订单信息',
		paneTitle2: '收货人信息',
		paneTitle3: '发货信息',
		paneTitle4: '商品信息'
	},
	member: {
		sno: '学号',
		regtime: '注册时间'
	},
	score: {
		tips1: '得分说明',
		tips2: '1、学生的成绩值由系统评分和教师评分之和组成；',
		tips3: '2、系统评分值最高为79分，教师评分值最高为21分，总和为100分。',
		tips4: '点击评分',
		ranking: '总分排行',
		systemRank: '智能评分排名',
		teacherRank: '教师评分排名',
		studentName: '学生名',
		shopName: '店铺名',
		account: '账号'
	},
	formItem: {
		shop: '店铺',
		shopkeeper: '店主',
		shopName: '店铺名称',
		shopId: '店铺ID',
		shopStatus: '店铺状态',
		shopOwnerCard: '店主身份证号',
		class: '班级',
		belongClass: '所属班级',
		belongCate: '所属类别',
		addresses: '联系地址',
		cellPhone: '手机号码',
		telephone: '联系电话',
		phone: '电话号码',
		ownerCardNo: '身份证号码',
		ownerCardFront: '身份证正面',
		ownerCardBack: '身份证反面',
		student: '学生',
		studentName: '学生姓名',
		openTime: '开店时间',
		zipcode: '邮政编码',
		status: '状态',
		startPay: '开启支付',
		account: '账号',
		orderSn: '订单号',
		orderStatus: '订单状态',
		orderTime: '下单时间',
		orderAmount: '订单总额',
		buyers: '买家',
		payType: '付款方式',
		payMode: '支付方式',
		payTime: '支付时间',
		freight: '运费',
		finishedTime: '完成时间',
		receiverName: '收货人姓名',
		fullAddress: '详细地址',
		shipmentNo: '发货单号',
		shipmentPlace: '发货地',
		consignor: '发货人',
		returnPlace: '退货地',
		statisticalTime: '统计时间',
		liveTitle: '直播标题',
		liveTime: '直播时间',
		goodsId: '商品ID',
		goodsName: '商品名称',
		cate: '分类',
		saleStatus: '出售状态',
		closeLive: '关播原因',
		liveType: '直播类型',
		bannedTime: '禁播时间'
	},
	tableHead: {
		title: '标题',
		actions: '操作',
		status: '状态',
		studentName: '学生姓名',
		studentAccount: '学生|账号',
		shop: '店铺',
		shopkeeper: '店主(账号)',
		shopName: '店铺名称',
		class: '班级',
		belongClass: '所属班级',
		openTime: '开通时间',
		startPay: '是否开启支付',
		overallScore: '综合评分',
		rank: '排名',
		account: '账号',
		systemScore: '系统得分',
		teacherScore: '教师评分',
		total: '总计',
		totalPoints: '总分',
		totalScore: '总评分',
		details: '详情',
		intelligentScore: '智能评分',
		fraction: '分数',
		analyticScore: '分项评分',
		comment: '评语',
		orderSn: '订单号',
		orderTime: '下单时间',
		orderAmount: '订单总额',
		orderStatus: '订单状态',
		buyers: '买家',
		payMode: '支付方式',
		member: '会员',
		logins: '登录次数',
		lastLogin: '最后登录',
		liveCount: '直播场次',
		liveTimes: '直播时长(min)',
		dealOrder: '成交订单数',
		dealAmount: '成交金额',
		statisticalDate: '统计日期',
		liveTitle: '直播标题',
		liveTime: '直播时间',
		newFans: '新增关注数(粉丝)',
		goodsId: '商品ID',
		goodsName: '商品名称',
		goodsStatus: '商品状态',
		clickCount: '在线点击总数',
		orderCount: '成单数',
		price: '价格',
		describe: '描述',
		paymentReal: '真实交易',
		paymentStatus: '真实交易状态',
		classStatus: '班级状态'
	},
	actions: {
		add: '添加',
		edit: '编辑',
		view: '查看',
		delete: '删除',
		confirm1: '确定',
		confirm2: '确 定',
		cancel1: '取消',
		cancel2: '取 消',
		clear: '清除条件',
		submit: '提交',
		reset: '重置',
		systemScore: '智能评分',
		teacherScore: '教师评分',
		closeLive: '关播',
		reason: '原因',
		illegalDelisting: '违规下架',
		illegalRelieve: '违规解除'
	},
	option: {
		shopStatus: [
			{ label: '待审核', value: '1' },
			{ label: '审核通过', value: '2' },
			{ label: '审核拒绝', value: '3' }
		],
		orderStatus: [
			{ label: '待付款', value: '1' },
			{ label: '待发货', value: '2' },
			{ label: '已发货', value: '3' },
			{ label: '已完成', value: '4' },
			{ label: '已关闭', value: '5' }
		],
		payType: [
			// { label: '虚拟支付', value: '1' },
			{ label: '在线支付', value: '2' }
		],
		memberKeyword: [
			// { label: '账号', value: '0' },
			{ label: '学号', value: '1' },
			// { label: '电子邮箱', value: '2' },
			{ label: '手机号码', value: '3' },
			{ label: '真实姓名', value: '4' }
		],
		memberSort: [
			{ label: '登录次数', value: '1' },
			{ label: '最后登录', value: '2' }
		],
		saleStatus: [
			{ label: '上架', value: '1' },
			{ label: '下架', value: '0' },
			{ label: '违规', value: '3' }
		],
		liveType: [
			{ label: '直播中', value: '0' },
			{ label: '强制下播', value: '1' }
		]
	},
	validate: {
		required: '必填项不能为空!',
		region: '请选择省市区!',
		phone: '请填写正确的手机号码!'
	},
	placeholder: {
		shopkeeper: '请输入店主',
		shopName: '请输入店铺名称',
		studentName: '请输入学生姓名',
		fullAddress: '详细地址',
		account: '请输入账号',
		guidance: '可点击输入您的指导意见',
		startDate: '开始日期',
		endDate: '结束日期',
		orderSn: '请输入订单号',
		buyers: '请输入买家',
		keyword: '请输入关键词',
		sort: '排序',
		liveTitle: '请输入直播标题',
		goodsName: '请输入商品名称',
		goodsId: '多个ID,英文逗号隔开',
		className: '请输入班级名称',
		closeLive: '关播原因',
		datetime: '选择日期时间'
	},
	filters: {
		orderStatus: { 0: '已取消', 11: '待付款', 20: '待发货', 30: '待收货', 40: '交易完成' }
	},
	dialog: {
		add: '添加',
		edit: '编辑'
	},
	dialogTitle: {
		closeLive: '强制关播'
	},
	confirm: {
		title: '提示',
		deleteText: '确认删除?',
		closeLive: '确认关播?',
		offShelf: '确认下架违规商品?',
		recoverShelves: '是否解除违规商品？',
		calculateSystem: '确定重新计算智能评分？'
	},
	successMsg: {
		add: '添加成功!',
		edit: '编辑成功!',
		update: '更新成功!',
    handle: '操作成功!',
		closeLive: '关播成功!'
	},
	errorMsg: {
		upload: '上传失败!'
	},
	cancelMsg: {
		delete: '已取消删除!',
		closeLive: '已取消关播!',
    handle: '已取消操作!'
	},
	breadcrumb: {
		shopManage: '店铺管理',
		orderManage: '订单管理',
		details: '详情',
		edit: '编辑'
	},
	tags: {
		open: '开启',
		close: '关闭',
		not: '无',
		member: '会员',
		shop: '店铺',
		live: '直播',
		order: '交易'
	},
	pagination: {
		prevText: '上一页',
		nextText: '下一页'
	}
}
export default teacher
