<template>
	<div class="mhome">
		<div class="mhome-view">
			<div class="mhome-tabs">
				<div class="tab-pane" :class="loginForm.type==1?'active':''" @click="handleChangeTabs(1)">{{ $t('login.studentTitle') }}</div>
				<div class="tab-pane" :class="loginForm.type==2?'active':''" @click="handleChangeTabs(2)">{{ $t('login.teacherTitle') }}</div>
			</div>
			<div class="mhome-login">
				<div class="login-logo"><img src="@/assets/images/m-login-logo.png" alt=""></div>
				<div class="login-main">
					<el-form :model="loginForm" ref="loginForm" :rules="loginRules">
						<el-form-item prop="username">
							<el-input v-model="loginForm.username" :placeholder="loginForm.type | nameFilter($t('login.placeholder.usernameJson'))" maxlength="20">
								<i slot="prefix" class="el-input__icon el-icon-user"></i>
							</el-input>
						</el-form-item>
						<el-form-item prop="password">
							<el-input type="password" v-model="loginForm.password" :placeholder="$t('login.placeholder.password')" maxlength="30">
								<i slot="prefix" class="el-input__icon el-icon-lock"></i>
							</el-input>
						</el-form-item>
						<!-- <div class="el-form-item">
							<div class="item-action"><a href="JavaScript:void(0);">忘记密码？</a></div>
						</div> -->
						<el-form-item>
							<el-button type="primary" class="btn-submit" @click="handleSubmit">{{ $t('login.logIn') }}</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import md5 from 'js-md5'
	export default {
		filters: {
			nameFilter(type, map) {
				return map[type]
			}
		},
		data() {
			var validateName = (rule, value, callback) => {
				if(value === '') {
					let json = this.$t('login.validate.usernameJson')
					callback(new Error(json[this.loginForm.type]));
				} else {
					callback();
				}
			}
			return {
				loginForm: {
					type: 1,
					username: '',
					password: '',
				},
				loginRules: {
					username: [{
						required: true,
						validator: validateName,
						trigger: 'blur'
					}, ],
					password: [{
						required: true,
						message: this.$t('login.validate.password'),
						trigger: 'blur'
					}, {
						min: 6,
						message: this.$t('login.validate.passwordMin'),
						trigger: 'blur'
					}],
				}
			}
		},
		methods: {
			handleChangeTabs(type){
				this.loginForm.type = type;
				this.$refs.loginForm.resetFields();
			},
			handleSubmit() {
				this.$refs.loginForm.validate((valid) => {
					if (valid) {
						let params = {
							type: this.loginForm.type,
							username: this.loginForm.username,
							password: md5(this.loginForm.password).toString(),
							app_key: this.$store.getters.appKey,
							uuid: this.$store.getters.uuid
						};
						this.$store.dispatch('user/login', params)
							.then(() => {
								this.$message({
									message: this.$t('login.successMsg'),
									customClass: 'wap-message',
									type: 'success'
								});
								if (this.$route.query.jump_url) {
									window.location.href = this.$route.query.jump_url
								} else {
									window.location.href = this.$workBasePath
								}
							})
							.catch(() => {
							})
					} else {
						return false;
					}
				});
			}
		}
	}
</script>
<style lang="scss" scoped>
	.mhome-view {
		height: 100%;
		background-color: #FFFFFF;
		.mhome-tabs {
			display: flex;
			border-bottom: 1px solid #E5E5E5;
			.tab-pane {
				flex: 1;
				text-align: center;
				font-size: 0.512rem;
				height: 1.568rem;
				line-height: 1.568rem;
				cursor: pointer;
				border-bottom: 2px solid transparent;
			}
			.tab-pane.active {
				color: $--seller-primary;
				border-bottom-color: $--seller-primary;
			}
		}
		.mhome-login {
			padding: 1.92rem 0.96rem;
			box-sizing: border-box;
		
			.login-logo {
				width: 2.88rem;
				height: 2.88rem;
				margin: 0 auto;
		
				img {
					width: 100%;
					height: 100%;
					border-radius: 50%;
					object-fit: cover;
				}
			}
		
			.login-main {
				padding-top: 1.28rem;
		
				::v-deep .el-form {
					.el-form-item {
						margin-bottom: 0.64rem;
						.el-form-item__error {
							padding-top: 0.128rem;
							font-size: 0.352rem;
						}
					}
					.mb16 {
						margin-bottom: 0.256rem;
					}
				}
		
				::v-deep .el-input {
					font-size: 0.416rem;
		
					.el-input__inner {
						height: 1.28rem;
						line-height: 1.28rem;
						padding: 0 0.32rem 0 1.024rem;
						border-radius: 0.256rem;
					}
		
					.el-input__prefix {
						left: 0.128rem;
		
						.el-input__icon {
							width: 0.832rem;
							line-height: 1.28rem;
							font-size: 0.448rem;
						}
					}
				}
		
				.item-action {
					display: flex;
					line-height: 0.64rem;
					justify-content: flex-end;
					align-items: center;
					font-size: 0.384rem;
		
					a {
						color: $--seller-primary;
					}
				}
				.btn-submit {
					width: 100%;
					padding: 0.384rem 0.64rem;
					font-size: 0.416rem;
					border-radius: 0.256rem;
				}
			}
		}
	}
</style>
