const live = {
	header: {
		home: '首页',
		liveNow: '立即直播',
		download: '下载客户端',
		console: '直播中控台',
	},
	home: {
		title: '加入灿若直播',
		subtitle: '学习直播电商新内容',
		content: '百所高校已入驻，下载灿若主播即可一键开播',
		settled: '一键入驻',
		download: '下载客户端',
	},
	upload: {
		title: '灿若主播',
		subtitle: '免费下载 快速开启直播',
		content: '百所高校已入驻，下载灿若主播即可一键开播',
		mobileTitle: '手机端APP',
		mobileSupport: '支持Android',
		pcTitle: 'PC端直播工具',
		pcSupport: '支持win7、win8、win10',
		pageTitle1: '电脑直播',
		'pageText1-1': '管理统计，助力分析直播效果',
		'pageText1-2': '支持快速查看每场直播的详情和回放',
		pageTitle2: '手机直播',
		'pageText2-1': '一键开播，随时随地想播就播',
		'pageText2-2': '急速装修，添加多互动场景，让你的直播间闪耀起来',
		pageTitle3: '电脑开播流程',
		'pageText3-1': '1、打开灿若直播中控台，点击“创建直播”',
		'pageText3-2': '2、在直播管理页，找到刚创建的直播，点击“正式开播”',
		pageTitle4: '手机开播流程',
		'pageText4-1': '1、打开灿若主播APP，点击底部蓝色按钮',
		'pageText4-2': '2、填写开播信息，点击“开始直播”',
		open: '展开详情',
		scan: '扫描二维码',
		downIos: '下载 Ios 正式版',
		downAndroid: '下载 Android 正式版',
		copyright: 'Copyright © 2013-现在 杭州灿若科技有限公司 Co.Ltd. All rights reserved.',
		ICPText1: '闽ICP备14008589号-1',
		ICPText2: '增值电信业务经营许可证[闽B2-20160039]',
		ICPText3: '闽公网安备 35021102002111号'
	}
}
export default live