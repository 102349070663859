import { getAlipay } from '@/api/payment'

const state = {
    appKey: 'uuc2c-pc'
}

const mutations = {}

const actions = {
    alipay({ state }, params) {
        params.app_key = state.appKey
        return new Promise((resolve, reject) => {
            getAlipay(params).then(response => {
                resolve(response)
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
