const mobile = {
	address: {
		region: '省、市、区',
		location: '请选择所在地区',
		province: '选择省份/地区',
		city: '选择城市',
		area: '选择区/县',
		select: '请选择',
		selectArea: '请选择地区'
	},
	goods: {
		text1: '选择',
		text2: '已选',
		text3: '颜色/尺码',
		text4: '发货',
		text5: '快递',
		text6: '免运费',
		text7: '保障',
		text8: '付款后15天内发货 · 7天无理由',
		text9: '宝贝评价',
		text10: '问大家',
		text11: '个回答',
		text12: '宝贝好不好，问问已买过的人',
		text13: '去提问',
		text14: '宝贝描述',
		text15: '卖家服务',
		text16: '物流服务',
		text17: '高',
		text18: '店铺推荐',
		text19: '宝贝详情',
		text20: '店铺',
		text21: '购买数量',
		text22: '库存',
		text23: '件',
		seckill: '秒杀',
		seckillPrice: '秒杀价',
		end: '距结束'
	},
	order: {
		selectAddress: '请选择地址',
		deliveryType: '配送方式',
		payType: '支付方式',
		fictitious: '虚拟支付',
		shopCoupon: '店铺优惠',
		couponText1: '满',
		couponText2: '元减',
		couponText3: '元',
		unuse: '不使用',
		remark: '订单备注',
		unremark: '无备注',
		shopDelivery: '商家配送',
		onlinePay: '在线支付',
		priceDetail: '价格明细',
		totalPrice: '商品总价',
		totalText1: '共',
		totalText2: '件',
		totalText3: '宝贝',
		reduce: '减',
		total: '合计',
		cartText1: '购物车',
		alipayText: '账户余额',
		alipayDesc: '货币基金转出资金付款',
		alipayTip: '请输入支付密码',
		actualPay: '实付款',
		freight: '运费',
		express: '快递',
		shopOffres: '店铺优惠',
		receiveInfo: '收货信息',
		number: '订单编号',
		payNumber: '支付流水号',
		createTime: '创建时间',
		payTime: '付款时间',
		deliveryTime: '发货时间',
		tradingTime: '成交时间',
		closeReason: '卖家关闭，原因',
		logisticPop: '物流单号'
	},
	pop: {
		logisticsCompany: '选择物流公司'
	},
	shop: {
		all: '综合',
		sales: '销量',
		news: '新品',
		price: '价格',
		text1: '描述相符',
		text2: '服务态度',
		text3: '物流服务',
		text4: '持平于同行',
		text5: '店铺介绍',
		text6: '店铺故事',
		text7: '精彩内容',
		text8: '店铺说明',
		text9: '基础信息',
		text10: '掌柜名',
		text11: '资质',
		text12: '已签署消保协议',
		text13: '优惠',
		text14: '满',
		text15: '可用',
		text16: '立即抢',
		rank1: '销量榜',
		rank2: '收藏榜',
		rank3: '新品榜',
		rank4: '个被种草',
		rank5: '个客官感兴趣'
	},
	live: {
		tag1: '预告',
		tag2: '直播',
		tag3: '回放',
		text1: '该直播未在原定时间开播，已过期',
		text2: '的小店',
		text3: '暂无简介',
		text4: '直播热卖宝贝',
		text5: '更多预告',
		text6: '全部预告',
		text7: '粉丝数',
		text8: '房间号',
		text9: '直播已结束',
		text10: '主播正在路上，请稍等!',
		text11: '更多直播',
		text12: '全部直播',
		text13: '更多回放',
		text14: '全部回放'
	},
	user: {
		myOrder: '我的订单',
		address: '常用地址'
	},
	evaluate: {
		publishTitle: '发表评价',
		itemDescribe: '描述相符',
		itemLogistics: '物流服务',
		itemService: '服务态度',
		text1: '此用户没有填写评价。',
		tips1: '公开头像昵称，大家可以看到我的主页'
	},
	refund: {
		title: '退款',
		success: '退款成功',
		totalMoney: '退款总金额',
		message: '退款信息',
		reason: '退款原因',
		money: '退款金额',
		applyTime: '申请时间',
		number: '退款编号',
		goods: '退款商品',
		selectService: '选择服务类型',
		selectTitle1: '我要退款(无需退货)',
		selectTip1: '没收到货，或与卖家协商同意不用退货只退款',
		selectTitle2: '我要退货退款',
		selectTip2: '已收到货，需要退还收到的货物',
		cargoStatus: '货物状态',
		cargoStatusSelect: '请先选择货物状态',
		editPriceTip1: '可修改，最多',
		editPriceTip2: '不可修改，最多',
		editPriceTip3: '含发货邮费',
		replenishText: '补充描述和凭证',
		sellerPending: '待商家处理中',
		stepTitle1: '提交申请',
		stepTitle2: '商家处理',
		stepTitle3: '寄回商品',
		stepTitle4: '商家退款',
		stepTitle5: '退款成功',
		stepText1: ['您已成功发起退款申请，请耐心等待商家处理。'],
		stepTips1: ['商家同意后，请按照给出的退货地址退货，并请记录退货运单号。','如商家拒绝，您可以修改申请后再次发起，商家会重新处理','如商家超时未处理，退货申请将达成，请按系统给出的退货地址退货'],
		stepText2: ['请退货并填写物流信息','商家已同意退货申请，请尽早退货'],
		stepTips2: ['未与商家协商一致，请无使用到付或平邮，以免商家拒签货物','钱款已从商家资金中冻结，请放心退货','请填写真实物流信息，逾期未填写，退货申请将撤销，撤销后若超出保障期，将无法再次发起售后申请'],
		stepText3: ['如果商家收到货并验货无误，将操作退款给您'],
		stepTips3: ['如果商家拒绝退款，需要您修改退款申请','如果商家超时未处理，将自动退款给您'],
		'statusTips1-1': '您已享未发货秒退权益，系统已退款',
		'statusTips1-2': '元给您',
		statusTips2: '您已撤销本次退款申请，如问题仍未解决，售后保障期内，您可以重新发起售后申请',
		logisticsTitle: '我已寄出',
		logisticsText: '点击填写物流单号',
		history: '协商历史'
	},
	formItem: {
		consignee: '收货人',
		mobilePhone: '手机号码',
		location: '所在地区',
		fullAddress: '详细地址',
		defaultAddress: '设为默认收货地址',
		logisticsNo: '物流单号',
		logisticsCompany: '物流公司',
		contactNumber: '联系电话'
	},
	placeholder: {
		name: '名字',
		mobilePhone: '手机号',
		fullAddress: '小区楼栋/乡村名称',
		orderRemark: '选填,请输入内容,付款后商家可见',
		evaluateText: '从多个角度评价宝贝,可以帮助更多想买的人',
		select: '请选择',
		replenishText: '补充描述，有助于商家更好的处理售后问题',
		logisticsNo: '请填写物流单号',
		logisticsCompany: '请选择物流公司',
		contactNumber: '请填写联系电话',
		logisticsDesc: '有助于帮助商家更好的处理售后问题'
	},
	validate: {
		consignee: '请填写收货人!',
		mobilePhone: '请填写正确的手机号码!',
		location: '请选择所在地区!',
		fullAddress: '请输入详细地址!',
	},
	actions: {
		saveGap: '保 存',
		confirm: '确定',
		cancel: '取消',
		submit: '提交',
		edit: '修改',
		submitOrder: '提交订单',
		viewAll: '查看全部',
		joinShop: '进店逛逛',
		allGoods: '全部宝贝',
		addCart: '加入购物车',
		buyNow: '立即购买',
		finish: '完成',
		manage: '管理',
		addAddress: '添加收货地址',
		goCart: '前往购物车',
		settlement: '结算',
		think: '我再想想',
		follow: '关注',
		unfollow: '已关注',
		broadcast: '正在直播',
		playback: '直播回放',
		moreBaby: '更多宝贝',
		openApp: '打开灿若主播(Androis)',
		downApp: '下载灿若主播(Androis)',
		draw: '领取',
		delete: '删除',
		confirmDelete: '确认删除',
		all: '全部',
		orderPayment: '待付款',
		orderDelivery: '待发货',
		orderReceiving: '待收货',
		orderEvaluate: '待评价',
		orderRefund: '退款/售后',
		confirmTrade: '确认交易',
		waiver: '放弃',
		continuePay: '继续付款',
		applyAfterSales: '申请售后',
		cancelOrder: '取消订单',
		immedPay: '立即付款',
		viewLogistics: '查看物流',
		viewInfo: '查看详情',
		confirmReceipt: '确认收货',
		immedEvaluation: '立即评价',
		deleteOrder: '删除订单',
		deleteRecord: '删除记录',
		publish: '发布',
		uploadPhoto: '拍买家秀',
		hasEvaluate: '已评价',
		evaluate: '评价',
		followUpEvaluate: '写追评',
		viewEvaluate: '查看评价',
		backHome: '返回首页',
		uploadVoucher: '上传凭证',
		maxLength3: '最多3张',
		maxLength6: '最多6张',
		details: '详细信息',
		refund: '退款',
		fillInNumber: '填写单号'
	},
	successMsg: {
		payment: '支付成功!',
		addCart: '加入购物车成功!',
		order: '下单成功!',
		follow: '关注成功!',
		draw: '领取成功!',
		evaluate: '评价成功',
		submit: '提交成功!',
		anonymityText1: '该评论已设置为公开评价',
		anonymityText2: '该评论已设置为匿名评价'
	},
	infoMsg: {
		inPayment: '付款中,请耐心等待...'
	},
	errorMsg: {
		orderNumber: '订单数量异常!',
		selectAddress: '请选择地址!',
		resubmit: '请不要多次提交!',
		goodsSpecs: '请选择商品规格!',
		goodsStock: '商品库存不足!',
		login: '未登录，请重新登录!!',
		selectGoods: '您还没选中宝贝哦!',
		supportApp: '仅支持Androis!',
		draw: '领取失败!',
		uploadType: '上传图片只能是 JPG/JPEG/PNG 格式!',
		uploadSize3M: '上传图片大小不能超过 3MB!',
		evaluateDescribe: '请对描述相符进行评分!',
		evaluateLogistics: '请对物流服务进行评分!',
		evaluateService: '请对服务态度进行评分!',
		cargoStatus: '请选择货物状态',
		refundReason: '请选择退款原因',
		selectLogistic: '请选择物流单号!',
		logisticsNo: '请填写物流单号',
		logisticsCompany: '请选择物流公司',
	},
	cancelMsg: {
		payment: '取消支付!',
		follow: '取消关注!',
		draw: '取消领取!',
		delete: '取消删除!'
	},
	confirm: {
		title: '提示',
		payText: '下单成功！是否立即支付？',
		payText2: '确认支付该订单？',
		deleteAddress: '确认要删除该地址吗？',
		deleteCartText1: '确认将这',
		deleteCartText2: '个宝贝删除？',
		drawCoupon: '确认领取优惠券？',
		waiverPay: '是否放弃付款？',
		deleteRecord: '确定删除记录？',
		cancelOrder: '是否取消订单？',
		deleteOrder: '确认删除订单？',
		receiptText: '确认收货？',
		anonymityText1: '确认我要匿名？',
		anonymityText2: '确认不想匿名了？'
	},
	unit: {
		etc: '等',
		amount: '元'
	},
	checkbox: {
		checkAll: '全选',
		defaultAddress: '默认地址',
		publicName: '公开'
	},
	options: {
		score: ['非常差', '差', '一般', '好', '非常好'],
		cargoStatus: [
			{ label: '未收到货', value: 1 },
			{ label: '已收到货', value: 2 }
		]
	},
	filters: {
		orderStatus: { 0: '交易关闭', 11: '待付款', 20: '待商家发货', 30: '卖家已发货', 40: '交易成功' },
		refundStatus: { 1: '退款中', 2: '退款成功', 3: '退款失败', 4: '拒绝退款', 5: '退款已关闭'},
		evaluateStatus: ['匿名', '公开'],
		refundType: ['退款', '退货']
	},
	empty: {
		comment: '竟然一条评价都没有',
		noLogistic: '无物流信息',
		nodata: '暂无数据',
		playback: '暂无回放内容'
	},
	tags: {
		default: '默认',
		fans: '粉丝',
		home: '首页',
		baby: '宝贝',
		sales: '人付款',
		listEnd: '到底啦',
		views: '观看',
		collects: '人收藏'
	}
}
export default mobile