<template>
	<el-container class="uu-body">
		<el-aside width="108px">
			<a href="/home/index" class="logo"><img src="@/assets/images/seller/logo.png" width="100%"></a>
			<el-scrollbar class="uu-aside">
				<el-menu
					class="uu-menu"
					:default-active="activeMenu"
				>
					<template v-for="item in routers">
						<app-link v-if="!item.hidden" :key="item.path" :to="item.path">
							<el-menu-item :index="item.path" :disabled="isExternal(item.path)">
								<item :icon="item.meta&&item.meta.icon" :title="item.meta.title" />
							</el-menu-item>
						</app-link>
						<div v-if="item.meta.title=='数据'" :key="item.path+'1'" class="item-splic"></div>
					</template>
				</el-menu>
			</el-scrollbar>
			<!-- <div class="uu-about">
				<el-tooltip effect="light" placement="right" popper-class="about-tooltip">
					<span class="text">{{$t('seller.home.aboutuu')}}</span>
					<template slot="content">
						<ul class="about-list">
							<li><a :href="$uulianBase || 'javascript:void(0);'" target="_blank"><svg-icon icon-class="earth"></svg-icon> {{$t('seller.home.uulianLink')}}</a></li>
						</ul>
					</template>
				</el-tooltip>
				<p>{{$t('seller.home.copyright')}}</p>
				<p>{{$t('seller.home.copyrightText')}}</p>
			</div> -->
		</el-aside>
		<el-container>
			<el-header height="75px">
				<div class="uu-head">
					<ul class="headbar">
						<li v-if="userInfo.identity && userInfo.identity>1">
							<router-link class="item-link" :to="{ name: 'teacherIndex' }">{{ $t('seller.header.teacher') }}</router-link>
						</li>
						<li>
							<el-dropdown>
								<div class="user-info">
									<img class="pic" :src="userInfo.storeLogo" alt="" @error="imageLoadError">
									<div class="item-block">
										<h3>{{userInfo.storeName}}</h3>
										<p>{{userInfo.userName}}</p>
									</div>
									<i class="el-icon-arrow-down el-icon--right"></i>
								</div>
								<el-dropdown-menu slot="dropdown">
									<router-link :to="{ name: 'merchantUserInfo'}">
										<el-dropdown-item><i class="el-icon-user-solid"></i>{{ $t('seller.header.userInfo') }}</el-dropdown-item>
									</router-link>
									<router-link :to="{ name: 'shopManage'}">
										<el-dropdown-item><i class="el-icon-s-shop"></i>{{ $t('seller.header.shopInfo') }}</el-dropdown-item>
									</router-link>
									<el-dropdown-item @click.native="handleLogout" divided>{{ $t('seller.header.logout') }}</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</li>
						<li v-if="userInfo.open_shop===2">
							<router-link class="item-link" :to="{ name: 'teachingList' }">{{ $t('seller.header.help') }}</router-link>
						</li>
					</ul>
				</div>
			</el-header>
			<router-view :key="key" />
		</el-container>
	</el-container>
</template>

<script>
	import path from 'path'
	import { mapGetters } from 'vuex'
	import { isExternal } from '@/utils/validate'
	import sellerRouter from '@/router/modules/seller'
	import AppLink from './components/Sidebar/Link'
	import Item from './components/Sidebar/Item'
	export default {
		components: { Item, AppLink },
		computed: {
			...mapGetters([
				'userInfo'
			]),
			key() {
				return this.$route.path
			},
			activeMenu() {
				const route = this.$route
				const { meta, path } = route
				if (meta.activeMenu) {
					return meta.activeMenu
				}
				return path
			}
		},
		data() {
			return {
				routers: [],
				basePath: ''
			}
		},
		created() {
			this.getMenuList()
			this.basePath = sellerRouter.path
		},
		methods: {
			getMenuList() {
				const menus = this.generateRoutes(sellerRouter.children, sellerRouter.path)
				this.routers = menus
			},
			generateRoutes(routes, basePath = '/') {
				const res = []
				for (let route of routes) {
					if (route.hidden) { continue }
					if (this.userInfo.open_shop !== 2 && !route.meta.menuAuth ) { continue }
					const data = {
						path: this.resolvePath(basePath, route.path),
						meta: route.meta
					}
					res.push(data)
				}
				return res
			},
			imageLoadError(e){
				e.target.src = this.$imageErrorLogo;
				e.target.style.objectFit = 'fill';
			},
			async handleLogout(){
				await this.$store.dispatch('user/logout')
				window.location.href = this.$loginBasePath + '?jump_url=' + window.location.href
			},
			resolvePath(basePath, routePath) {
				if (isExternal(routePath)) {
					return routePath
				}
				if (isExternal(basePath)) {
					return basePath
				}
				return path.resolve(basePath, routePath)
			},
			isExternal(path) {
				return isExternal(path)
			}
		},
	}
</script>

<style lang="scss" scoped>
	.uu-body {
		height: 100%;
		.el-aside {
			background-color: $--seller-aside-background;
		}
		.logo {
			height: 50px;
			display: block;
			padding: 24px 12px 0;
		}
		.el-header {
			padding: 0;
			background-color: $--seller-aside-background;
			border-bottom: $--seller-header-border;
		}
	}
	.uu-aside {
		margin-top: 10px;
		height: calc(100vh - 100px);
	}
	.uu-about {
		padding-top: 8px;
		text-align: center;
		.text {
			color: #7a8599;
			font-size: 12px;
			cursor: pointer;
			line-height: 1.28571;
			margin-bottom: 23px;
			display: inline-block;
		}
		p {
			margin-top: 5px;
			color: #a1b1cd;
			font-size: 12px;
			line-height: 1.28571;
		}
	}
	.uu-menu {
		padding-left: 12px;
		border-right: none;
		background-color: transparent;
		.el-menu-item {
			color: #7a8599;
			height: 48px;
			line-height: 48px;
			padding: 0 12px!important;
			border-radius: 10px 0 0 10px;
			&.is-active {
				color: $--seller-primary;
				background-color: $--seller-menu-active-background;
			}
			&.is-disabled {
				opacity: 1;
				cursor: pointer;
			}
		}
		.item-splic {
			height: 1px;
			width: 46px;
			background: #d7dbe0;
			background-color: #d7dbe0;
			margin: 12px auto 6px;
		}
	}
	.uu-head {
		padding: 0 20px;
		height: 100%;
		border-top-left-radius: 20px;
		background-color: #ffffff;
		.headbar {
			float: right;
			li {
				display: inline-block;
				vertical-align: middle;
				margin-left: 20px;
			}
			.user-info {
				display: flex;
				height: 74px;
				align-items: center;
				cursor: pointer;
				.pic {
					width: 30px;
					height: 30px;
					margin: 0 5px;
					border-radius: 15px;
					object-fit: cover;
				}
				.item-block {
					display: inline-block;
					margin-right: 12px;
					h3 {
						font-size: 14px;
						margin-bottom: 4px;
					}
					p {
						font-size: 12px;
						color: #999999;
					}
					
				}
			}
			.item-link {
				color: #555555;
				font-size: 14px;
				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
	::v-deep .uu-box {
		flex: 1;
		height: calc(100vh - 75px);
		>.el-scrollbar__wrap {
			height: calc(100% + 17px);
			>.el-scrollbar__view {
				min-width: 1024px;
			}
		}
		.el-scrollbar__bar {
			z-index: 100;
		}
	}
	.about-list {
		a {
			cursor: pointer;
			&:hover {
				color: $--seller-primary;
			}
		}
	}
</style>