// 登录
import login from '@/views/mobile/login/login'

const mLoginRouter = [{
	path: "/m",
	name: "login",
	component: login,
	meta: {
		title: "用户登录-直播电商实战教学系统"
	}
}, {
	path: '*',
	redirect: '/m'
}]

export default mLoginRouter
