import request from '@/utils/request'
import { param2Obj } from '@/utils'

export function login(data) {
  return request({
    url: '/liveapi/login',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getInfo(data) {
  return request({
    url: '/liveapi/member/info',
    method: 'post',
    data: param2Obj(data)
  })
}

export function logout(data) {
  return request({
    url: '/liveapi/logout',
    method: 'post',
		data: param2Obj(data)
  })
}

export function fetchRegion(data) {
  return request({
    url: '/region/childs',
    method: 'post',
		data: param2Obj(data)
  })
}

export function getAppid(data) {
  return request({
    url: '/liveapi/yyuAuth/param',
    method: 'post',
		data: param2Obj(data)
  })
}

export function firstLogin(data) {
  return request({
    url: '/liveapi/yyuAuth/login',
    method: 'post',
		data: param2Obj(data)
  })
}

