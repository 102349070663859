// 卖家中心
import sellerRouter from './seller'
import teacherRouter from './teacher'

const workRouter = [{
	path: '/home/open-shop-landing',
	name: 'openShopLanding',
	component: () => import('@/views/seller/home/landing'),
	meta: {
		title: '申请开店',
		menuAuth: true
	}
}, {
	path: '/home/close-shop',
	name: 'closeShop',
	component: () => import('@/views/seller/home/closeShop'),
	meta: {
		title: '店铺关闭',
		menuAuth: true
	}
},
sellerRouter,
teacherRouter,
{
	path: '/home/decorate/wpPageEditor/:id',
	name: 'wpPageEditor',
	component: () => import('@/views/seller/decorate/wpPageEditor'),
	meta: {
		title: '店铺装修-页面装修编辑器'
	}
}, {
	path: '/home/decorate/pageEditor/:id',
	name: 'pageEditor',
	component: () => import('@/views/seller/decorate/pageEditor'),
	meta: {
		title: '店铺装修-无线页面'
	}
}, {
	path: '/home/decorate',
	component: () => import('@/views/seller/decorate/public'),
	redirect: '/home/decorate/index',
	children: [{
		path: 'index',
		name: 'decorateIndex',
		component: () => import('@/views/seller/decorate/index'),
		meta: { title: '店铺装修管理' }
	}, {
		path: 'category',
		name: 'decorateCategory',
		component: () => import('@/views/seller/decorate/category'),
		meta: { title: '宝贝分类管理' }
	}, {
		path: 'material',
		name: 'decorateMaterial',
		component: () => import('@/views/seller/decorate/material'),
		meta: { title: '素材中心' }
	}]
}, {
	path: '*',
	redirect: '/home/index'
}]

export default workRouter