import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 教学&测评
export function fetchArticleMenu(data) {
  return request({
    url: '/seller/teaching/center/article/left/menu',
    method: 'post',
		data: param2Obj(data)
  })
}

export function fetchArticleList(data) {
  return request({
    url: '/seller/teaching/center/article/center/content',
    method: 'post',
		data: param2Obj(data)
  })
}

export function fetchArticleRecommend(data) {
  return request({
    url: '/seller/teaching/center/article/recommend',
    method: 'post',
		data: param2Obj(data)
  })
}

export function articleList(data) {
  return request({
    url: '/seller/teaching/center/article/page',
    method: 'post',
		data: param2Obj(data)
  })
}

export function articleInfo(data) {
  return request({
    url: '/seller/teaching/center/article/detail',
    method: 'post',
		data: param2Obj(data)
  })
}

export function fetchScore(data) {
  return request({
    url: '/seller/evaluation/person/achievement/index',
    method: 'post',
		data: param2Obj(data)
  })
}

export function scoreInfo(data) {
  return request({
    url: '/seller/evaluation/person/achievement/detail',
    method: 'post',
		data: param2Obj(data)
  })
}

export function scoreRanking(data) {
  return request({
    url: '/seller/evaluation/person/achievement/score/ranking',
    method: 'post',
		data: param2Obj(data)
  })
}
