<template>
	<div>
		<el-scrollbar>
			<el-menu
				:default-active="activeMenu"
				:default-openeds="openeds"
				:background-color="menuBg"
				:text-color="menuText"
				:active-text-color="menuActiveText"
			>
				<sidebar-item v-for="route in routers" :key="route.path" :item="route" :base-path="route.path" />
			</el-menu>
		</el-scrollbar>
	</div>
</template>

<script>
	import liveplatformRouter from '@/router/modules/liveplatform'
	import SidebarItem from './SidebarItem'
	import theme from "@/assets/css/mixin.scss"
	
	export default {
		components: { SidebarItem },
		props: {
			isLive: {
				type: Boolean,
				default: false
			},
			menuBg: {
				type: String,
				default: '#ffffff'
			},
			menuText: {
				type: String,
				default: '#303133'
			},
			menuActiveText: {
				type: String,
				default: theme.colorPrimary
			}
		},
		computed: {
			activeMenu() {
				const route = this.$route
				const { meta, path } = route
				if (meta.activeMenu) {
					return meta.activeMenu
				}
				return path
			}
		},
		data() {
			return {
				routers: liveplatformRouter,
				openeds: []
			}
		},
		created() {
			if(this.isLive) {
				this.openeds = ['/home/live']
			}
		}
	}
</script>

<style lang="scss" scoped>
	.el-menu {
		min-height: 100%;
		border-right: none;
	}
</style>