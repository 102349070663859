<template>
	<div class="sycm-footer">
		<p>{{$t('sycm.footer.text1')}}</p>
		<!-- <p>
			<span>{{$t('sycm.footer.text2')}}</span>
			<span>{{$t('sycm.footer.text3')}}</span>
			<span><i class="beian-icon"></i>{{$t('sycm.footer.text4')}}</span>
		</p> -->
	</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.sycm-footer {
	min-width: 1240px;
	padding: 30px 0;
	text-align: center;
	border-top: 1px solid #D1D7DC;
	background-color: #ffffff;
	font-size: 12px;
	line-height: 1.5;
	color: #666666;

	span {
		margin: 0 5px;
	}

	.beian-icon {
		width: 16px;
		height: 16px;
		display: inline-block;
		margin-right: 4px;
		vertical-align: middle;
		background-image: url('~@/assets/images/beian-i.png');
		background-size: 100% auto;
		background-repeat: no-repeat;
	}
}

@media (min-width: 1600px) {
	.sycm-footer {
		min-width: 1440px;
	}
}
</style>