<template>
	<div class="sycm-header">
		<div class="header-wrap">
			<div class="header-top">
				<div class="logo-wrap">
					<a href="/" class="logo-main">
						<img class="pic" src="~@/assets/images/sycm/logo.png" alt="">
						<span class="name">{{$t('sycm.header.logo')}}</span>
					</a>
					<div class="logo-split"></div>
					<div class="logo-store">{{ userInfo.storeName }}</div>
					<el-tag type="yellow" effect="dark" size="mini">{{$t('sycm.header.shopkeeper')}}</el-tag>
				</div>
				<div class="top-bar">
					<ul>
						<li><a :href="$workBasePath + '/home/teaching/list'" target="_blank">{{$t('sycm.header.help')}}</a></li>
						<li @click="handleLogout">{{$t('sycm.header.logout')}}</li>
					</ul>
				</div>
			</div>
			<ul class="header-menu" v-if="menuList">
				<template v-for="(item, index) in menuList">
					<li :key="item.path" :class="{ active: item.path == $route.matched[0].path }">
						<router-link :to="item.path">{{ item.meta ? item.meta.title : '' }}</router-link>
					</li>
					<li v-if="index == 1" class="item-split" :key="index"><span class="split-inner"></span></li>
				</template>
			</ul>
		</div>
	</div>
</template>

<script>
import path from 'path'
import { mapGetters } from 'vuex'
import sycmRouter from '@/router/modules/sycm'
export default {
	computed: {
		...mapGetters([
			'userInfo'
		]),
	},
	data() {
		return {
			menuList: null
		}
	},
	created() {
		this.getMenuList()
	},
	methods: {
		getMenuList() {
			this.menuList = this.generateRoutes(sycmRouter)
		},
		generateRoutes(routes, basePath = '/') {
			const res = []
			for (let route of routes) {
				if (route.hidden) { continue }
				const data = {
					path: path.resolve(basePath, route.path),
					meta: route.meta
				}
				res.push(data)
			}
			return res
		},
		async handleLogout(){
			await this.$store.dispatch('user/logout')
			window.location.href = this.$loginBasePath + '?jump_url=' + window.location.href
		}
	}
}
</script>

<style lang="scss" scoped>
.sycm-header {
	width: 100%;
	min-width: 1240px;
	box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
	background: $--sycm-primary;

	.header-wrap {
		padding-top: 20px;
		width: 1210px;
		margin: auto;

		.header-top {
			display: flex;
			padding-bottom: 10px;
			justify-content: space-between;

			.logo-wrap {
				color: #ffffff;
				display: flex;
				align-items: center;

				.logo-main {
					color: #ffffff;
					display: flex;
					align-items: center;

					.pic {
						width: 30px;
						height: 30px;
					}

					.name {
						font-size: 22px;
						margin-left: 10px;
					}

				}

				.logo-split {
					width: 1px;
					height: 21px;
					background-color: #ffffff;
					opacity: .3;
					margin-right: 14px;
					margin-left: 6px;
				}

				.logo-store {
					font-size: 22px;
					margin-right: 10px;
				}

				.el-tag--yellow {
					border-radius: 0;
					color: $--sycm-tag-color;
					font-weight: 600;
					border-color: $--sycm-yellow;
					background-color: $--sycm-yellow;
				}
			}

			.top-bar {
				line-height: 22px;
				margin-top: 4px;

				li {
					margin-left: 28px;
					color: #fefefe;
					display: inline-block;
					vertical-align: middle;
					cursor: pointer;
					opacity: .6;
					font-size: 14px;

					&:hover {
						opacity: 1;
						color: $--sycm-hover-1;
					}
				}
			}
		}

		.header-menu {
			display: flex;

			li {
				color: #ffffff;
				height: 50px;
				line-height: 50px;
				flex-shrink: 0;
				display: flex;
				align-items: center;
				font-size: 16px;
				padding: 0 10px;
				position: relative;
				margin-right: 20px;

				&:hover {
					color: $--sycm-yellow;
				}

				&.item-split {
					.split-inner {
						height: 16px;
						width: 1px;
						opacity: .3;
						display: inline-block;
						vertical-align: middle;
						background-color: #ffffff;
					}
				}
			}

			li.active {
				color: $--sycm-yellow;

				&::before {
					content: "";
					width: 100%;
					height: 3px;
					left: 0;
					bottom: 0;
					position: absolute;
					background: $--sycm-yellow;
				}
			}
		}
	}
}

@media (min-width: 1600px) {
	.sycm-header {
		min-width: 1440px;

		.header-wrap {
			width: 1430px;
		}
	}
}
</style>