<template>
  <div id="app">
    <router-view :key="key">
    </router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
	computed: {
		key() {
			return this.$route.fullPath
		}
	}
}
</script>

<style lang="scss">
	@import './assets/css/reset.css';
	body,html,#app {
		height: 100%;
	}
	.el-message {
		&.wap-message {
			width: 60%;
			max-width: 380px;
			min-width: auto;
			padding: 0.256rem 0.256rem 0.256rem 0.32rem;
			.el-message__icon {
				font-size: 0.416rem;
			}
			.el-message__content {
				font-size: 0.416rem;
				line-height: 1.5;
			}
		}
	}
	.el-message-box {
		&.wap-message-confirm {
			width: 60%;
			max-width: 380px;
			min-width: auto;
		}
	}
	.el-popper {
		&.live-select-popper {
			border-color: $--live-gray-black-23;
			background-color: $--live-gray-black-23;
			&[x-placement^=bottom] {
				.popper__arrow {
					border-bottom-color: $--live-gray-black-23;
					&:after {
						border-bottom-color: $--live-gray-black-23;
					}
				}
			}
			.el-select-dropdown__item {
				color: $--live-text-color-2;
				&.hover,&:hover {
					background-color: $--live-gray-white-4;
				}
				&.selected {
					background-color: $--live-primary-black-82;
				}
			}
			.el-autocomplete-suggestion__list {
				li {
					color: $--live-text-color-2;
					&.hover,&:hover {
						background-color: $--live-gray-white-4;
					}
				}
			}
		}
		&.el-cascader__dropdown {
			.el-cascader-panel {
				max-height: 257px;
			}
		}
	}
</style>
