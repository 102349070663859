import { login, logout, getInfo, firstLogin } from '@/api/user'
import { getToken, setToken, removeToken, setStorage, removeStorage } from '@/utils/auth'

const state = {
  token: getToken(),
  uuid: 'pc',
  appKey: 'uuc2c-pc',
  secret: 'fb50e59f61d828cabc4a5661e2fbff57',
	userId: '',
  userInfo: {}
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER_ID: (state, userId) => {
    state.userId = userId
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  }
}

const actions = {
  // user login
  login({ commit }, params) {
    return new Promise((resolve, reject) => {
      login(params).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        commit('SET_USER_ID', data.userId)
				
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  firstLogin({ commit, state }, params) {
    params.app_key = state.appKey
    return new Promise((resolve, reject) => {
      firstLogin(params).then(response => {
        const { data } = response
        commit('SET_TOKEN', data.token)
        commit('SET_USER_ID', data.userId)
				
        setToken(data.token)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
			const params = {
				userId: state.userId,
				uuid: state.uuid,
				app_key: state.appKey,
			}
      getInfo(params).then(response => {
        const { data } = response
        commit('SET_USER_INFO', data)
				setStorage(state.token)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout({ app_key: state.appKey }).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_USER_INFO', {})
        removeToken()

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },
  // has token
  hasToken({ commit }, token) {
    return new Promise(resolve => {
      const data = token || getToken()
      if(data) {
        commit('SET_TOKEN', data)
        setToken(data)
      }
      resolve(data)
    })
  },
  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_USER_INFO', {})
      removeToken()
			removeStorage()
      resolve()
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
