import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 支付接口
export function getAlipay(data) {
    return request({
        url: '/order/payment',
        method: 'post',
        data: param2Obj(data)
    })
}