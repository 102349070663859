import VueCookies from 'vue-cookies'

const TokenKey = process.env.VUE_APP_TOKEN_KEY
const domain = window.location.hostname === 'localhost' ? 'localhost' : process.env.VUE_APP_BASE_URL

export function getToken() {
	return VueCookies.get(TokenKey)
}

export function setToken(token) {
	return VueCookies.set(TokenKey, token, null, null, domain)
}

export function removeToken() {
	return VueCookies.remove(TokenKey, null, domain)
}

export function getStorage() {
	return localStorage.getItem(TokenKey)
}

export function setStorage(token) {
	return localStorage.setItem(TokenKey, token)
}

export function removeStorage() {
	return localStorage.removeItem(TokenKey)
}

// 本地域名切换
/**
 * 全局登录：login;商品展示：item;卖家中心：work;直播门户：live;直播中控台：liveplatform;生意参谋：sycm;
 * @returns 当前域名
 */
export function getHost() {
	const host = window.location.host
	if (host.indexOf(process.env.VUE_APP_BASE_URL) < 0) {
		return 'liveplatform.' + process.env.VUE_APP_BASE_URL
	}
	return host
}
