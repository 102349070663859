<template>
    <el-container class="uu-submain">
        <el-aside width="240px">
            <el-scrollbar>
                <div class="submenu-title"><router-link :to="{ name: 'teachingList' }">{{ submenuTitle }}</router-link></div>
                <el-menu class="submenu-list" :default-active="activeMenu" :default-openeds="openeds">
                    <el-submenu v-for="item in articleMenu" :key="item.cate_id" :index="item.cate_id.toString()">
                        <template slot="title">{{item.cate_name}}</template>
                        <el-menu-item v-for="child in item.list_article" :key="child.article_id" :index="child.article_id.toString()">
                            <router-link :to="{ name: 'teachingInfo', params: { id: child.article_id }}">{{ child.title }}</router-link>
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </el-scrollbar>
        </el-aside>
        <router-view :key="key" />
    </el-container>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters([
            'articleMenu'
        ]),
        key() {
            return this.$route.path
        },
        activeMenu() {
            const { params } = this.$route
            const id = params.id ? params.id.toString() : ''
            return id
        },
        submenuTitle() {
            const route = this.$route
            const { matched } = route
            return matched[1].meta.title
        }
    },
    data() {
        return {
            menuList: [],
            openeds: []
        }
    },
    created() {
        this.getMenuList()
        if(this.$route.params.id>0) {
            this.openeds.push(this.$route.params.id)
        }
    },
    methods: {
        getMenuList() {
            this.$store.dispatch('article/getMenu')
        }
    }
}
</script>

<style lang="scss" scoped>
.uu-submain {
    height: calc(100vh - 75px);

    .el-aside {
        height: 100%;
        overflow: hidden;
        border-right: $--seller-subaside-border;
        background-color: $--seller-subaside-background;
        .el-scrollbar {
            width: 100%;
            height: 100%;
        }
    }

    .submenu-title {
        margin-bottom: 20px;
        padding-left: 20px;
        padding-top: 20px;
        font-size: 20px;
        line-height: 1em;
        color: #333;
        a {
            color: inherit;
            &:hover {
                color: $--seller-primary;
            }
        }
    }
}
::v-deep .el-menu {
    &.submenu-list {
        border-right: none;
        .el-menu-item {
            height: 40px;
            line-height: 40px;
            a {
                color: inherit;
            }
            &:hover,&:focus {
                background: none;
            }
            &:hover {
                color: $--seller-primary;
            }
        }
        .el-submenu {
            .el-submenu__title {
                height: 40px;
                line-height: 40px;
                &:hover,&:focus {
                    background: none;
                }
                &:hover {
                    color: $--seller-primary;
                }
            }
            &.is-active {
                .el-submenu__title {
                    color: $--seller-primary;
                    i {
                        color: $--seller-primary;
                    }
                }
            }
        }
    }
}
</style>