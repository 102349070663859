// PC全局登录

const loginRouter = [{
	path: "/",
	component: () => import('@/views/login/login'),
	meta: {
		title: "用户登录-直播电商实战教学系统"
	}
}, {
	path: '/auth-redirect',
	component: () => import('@/views/login/auth-redirect'),
	meta: {
		title: "用户登录-直播电商实战教学系统"
	}
}, {
	path: '*',
	redirect: '/'
}]

export default loginRouter
