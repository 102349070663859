<template>
	<el-container class="uu-submain">
		<el-aside width="127px" v-if="routers.length>0">
			<div class="submenu-title">{{submenuTitle}}</div>
			<el-menu
				class="uu-submenu"
				:default-active="activeMenu"
				:default-openeds="openeds"
			>
				<template v-for="item in routers">
					<template v-if="!item.children">
						<app-link v-if="!item.hidden" :key="item.path" :to="item.path">
							<el-menu-item :index="item.path" :disabled="isExternal(item.path)">
								<item :title="item.title" />
							</el-menu-item>
						</app-link>
					</template>
					<el-submenu v-else ref="subMenu" :key="item.path" :index="item.path" popper-append-to-body>
						<template slot="title">
							<item :title="item.title" />
						</template>
						<template v-for="child in item.children">
							<app-link v-if="!child.hidden" :key="child.path" :to="child.path">
								<el-menu-item :index="child.path" :disabled="isExternal(child.path)">
									<item :title="child.title" />
								</el-menu-item>
							</app-link>
						</template>
					</el-submenu>
				</template>
			</el-menu>
		</el-aside>
		<router-view :key="key" />
	</el-container>
</template>

<script>
	import path from 'path'
	import { isExternal } from '@/utils/validate'
	import { mapGetters } from 'vuex'
	import sellerRouter from '@/router/modules/seller'
	import AppLink from './components/Sidebar/Link'
	import Item from './components/Sidebar/Item'
	export default {
		components: { Item, AppLink },
		computed: {
			...mapGetters([
				'userInfo'
			]),
			key() {
				return this.$route.path
			},
			activeMenu() {
				const route = this.$route
				const { meta, path } = route
				if (meta.subActiveMenu) {
					return meta.subActiveMenu
				}
				return path
			},
			submenuTitle() {
				const route = this.$route
				const { matched } = route
				return matched[1].meta.title
			}
		},
		data() {
			return {
				serviceRoutes: [],
				routers: [],
				basePath: '',
				openeds: [],
			}
		},
		created() {
			this.getMenuList()
		},
		methods: {
			getMenuList() {
				const route = this.$route
				const { matched } = route
				const routes = this.generateRoutes(sellerRouter.children, sellerRouter.path)
				let data = []
				let childPath = []
				routes.forEach(item => {
					if(matched[1].path === item.path) {
						data = item.children
						item.children.forEach(child => {
							childPath.push(child.path)
						})
					}
				})
				this.openeds = childPath
				this.routers = data
			},
			generateRoutes(routes, basePath = '/') {
				const res = []
				for (let route of routes) {
					if (route.hidden) { continue }
					if (this.userInfo.open_shop !== 2 && !route.meta.menuAuth ) { continue }
					const data = {
						path: this.resolvePath(basePath, route.path),
						title: route.meta && route.meta.title
					}
					if (route.children) {
						data.children = this.generateRoutes(route.children, data.path)
					}
					res.push(data)
				}
				return res
			},
			resolvePath(basePath, routePath) {
				if (isExternal(routePath)) {
					return routePath
				}
				if (isExternal(basePath)) {
					return basePath
				}
				return path.resolve(basePath, routePath)
			},
			isExternal(path) {
				return isExternal(path)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.uu-submain {
		height: calc(100vh - 75px);
		.el-aside {
			height: 100%;
			border-right: $--seller-subaside-border;
			background-color: $--seller-subaside-background;
		}
		.submenu-title {
			margin-bottom: 20px;
			padding-left: 20px;
			padding-top: 20px;
			font-size: 20px;
			line-height: 1em;
			color: #333;
		}
	}
	.uu-submenu {
		padding: 0 9px;
		border-right: none;
		.el-menu-item {
			height: 42px;
			line-height: 42px;
			margin-bottom: 6px;
			padding: 0 9px!important;
			border-radius: 9px;
			min-width: auto;
			font-size: 13px;
			&:hover {
				color: $--seller-submenu-hover-font;
				background-color: $--seller-submenu-hover-background;
			}
			&.is-active {
				color: $--seller-submenu-active-font;
				background-color: $--seller-submenu-active-background;
			}
			&.is-disabled {
				opacity: 1;
				cursor: pointer
			}
		}
		::v-deep .el-submenu {
			.el-submenu__title {
				height: 42px;
				line-height: 42px;
				margin-bottom: 6px;
				padding: 0 9px!important;
				&:hover {
					background-color: transparent;
				}
			}
			.el-menu-item {
				padding-left: 16px!important;
			}
		}
	}
</style>