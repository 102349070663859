<template>
	<div class="container">
		<uu-header></uu-header>
		<router-view :key="key" />
		<uu-footer></uu-footer>
	</div>
</template>

<script>
	import uuHeader from './components/home/header';
	import uuFooter from './components/home/footer';
	export default {
		components: { uuHeader, uuFooter },
		computed: {
			key() {
				return this.$route.path
			}
		}
	}
</script>

<style lang="scss" scoped>
	.container {
		overflow: auto;
	}
</style>