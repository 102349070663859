// 教师中心
import Layout from '@/layout/teacher'
import subLayout from '@/layout/subTeacher'

const teacherRouter = {
	path: '/teacher',
	component: Layout,
	redirect: '/teacher/index',
	children: [{
		path: 'index',
		name: 'teacherIndex',
		component: () => import('@/views/teacher/platform'),
		meta: {
			title: '数据概览',
			icon: 'el-icon-s-platform'
		}
	}, {
		path: 'class-manage',
		name: 'teacherClass',
		component: () => import('@/views/teacher/classManage'),
		meta: {
			title: '班级管理',
			icon: 'el-icon-s-flag'
		}
	}, {
		path: 'member-manage',
		name: 'teacherMember',
		component: () => import('@/views/teacher/memberManage'),
		meta: {
			title: '会员管理',
			icon: 'el-icon-s-custom'
		}
	}, {
		path: 'shop-manage',
		name: 'teacherShop',
		component: () => import('@/views/teacher/shopManage'),
		meta: {
			title: '店铺管理',
			icon: 'el-icon-s-shop'
		}
	}, {
		path: 'shop-info/:id',
		name: 'teacherShopInfo',
		hidden: true,
		component: () => import('@/views/teacher/shopInfo'),
		meta: {
			title: '店铺详情',
			activeMenu: '/teacher/shop-manage'
		}
	}, {
		path: 'shop-edit/:id',
		name: 'teacherShopEdit',
		hidden: true,
		component: () => import('@/views/teacher/shopEdit'),
		meta: {
			title: '店铺编辑',
			activeMenu: '/teacher/shop-manage'
		}
	}, {
		path: 'live',
		redirect: '/teacher/live/index',
		component: subLayout,
		children: [{
			path: 'index',
			name: 'teacherLive',
			component: () => import('@/views/teacher/liveManage'),
			meta: { title: '直播总场次', activeMenu: '/teacher/live' }
		}, {
			path: 'field',
			name: 'teacherLiveField',
			component: () => import('@/views/teacher/liveField'),
			meta: { title: '直播分场次', activeMenu: '/teacher/live' }
		}, {
			path: 'broadcast',
			name: 'teacherLiveBroadcast',
			component: () => import('@/views/teacher/liveBroadcast'),
			meta: { title: '直播管控', activeMenu: '/teacher/live' }
		}],
		meta: {
			title: '直播管理',
			icon: 'el-icon-video-camera-solid'
		}
	}, {
		path: 'goods-manage',
		name: 'teacherGoods',
		component: () => import('@/views/teacher/goodsManage'),
		meta: {
			title: '商品管理',
			icon: 'el-icon-s-goods'
		}
	}, {
		path: 'order-manage',
		name: 'teacherOrder',
		component: () => import('@/views/teacher/orderManage'),
		meta: {
			title: '交易管理',
			icon: 'el-icon-s-order'
		}
	}, {
		path: 'order-info/:id',
		name: 'teacherOrderInfo',
		hidden: true,
		component: () => import('@/views/teacher/orderInfo'),
		meta: {
			title: '交易详情',
			activeMenu: '/teacher/order-manage'
		}
	}, {
		path: 'score-ranking',
		name: 'teacherScoreRanking',
		component: () => import('@/views/teacher/scoreRanking'),
		meta: {
			title: '评分管理',
			icon: 'el-icon-star-on'
		}
	}, {
		path: 'score-system/:id',
		name: 'teacherScoreSystem',
		hidden: true,
		component: () => import('@/views/teacher/scoreSystem'),
		meta: {
			title: '评分管理',
			activeMenu: '/teacher/score-ranking'
		}
	}, {
		path: 'score-manage/:id',
		name: 'teacherScoreManage',
		hidden: true,
		component: () => import('@/views/teacher/scoreManage'),
		meta: {
			title: '评分管理',
			activeMenu: '/teacher/score-ranking'
		}
	}]
}
export default teacherRouter