import Layout from '@/layout/sycm'

const sycmRouter = [{
	path: "/portal",
	component: Layout,
	redirect: '/portal/home',
	meta: { title: "首页" },
	children: [{
		path: "home",
		name: "SycmPortalHome",
		component: () => import('@/views/sycm/portal/home'),
		meta: {
			title: "首页"
		}
	}]
}, {
	path: "/ipoll",
	component: Layout,
	redirect: '/ipoll/index',
	meta: { title: "实时", subtitle: '实时直播', icon: "real_time" },
	children: [{
		path: "index",
		name: "SycmIpollIndex",
		component: () => import('@/views/sycm/ipoll/index'),
		meta: {
			title: "实时概况"
		}
	}, {
		path: "source",
		name: "SycmIpollSource",
		component: () => import('@/views/sycm/ipoll/source'),
		meta: {
			title: "实时来源"
		}
	}, {
		path: "rank",
		name: "SycmIpollRank",
		component: () => import('@/views/sycm/ipoll/rank'),
		meta: {
			title: "实时榜单"
		}
	}, {
		path: "topay",
		name: "SycmIpollTopay",
		component: () => import('@/views/sycm/ipoll/topay'),
		meta: {
			title: "实时催付宝"
		}
	}]
}, {
	path: "/flow",
	component: Layout,
	redirect: '/flow/overview',
	meta: { title: "流量", subtitle: '流量纵横', icon: "flow" },
	children: [{
		path: "overview",
		name: "SycmFlowOverview",
		component: () => import('@/views/sycm/flow/overview'),
		meta: {
			title: "流量看板"
		}
	}, {
		path: "analysis",
		name: "SycmFlowAnalysis",
		component: () => import('@/views/sycm/flow/analysis'),
		meta: {
			title: "访客分析"
		}
	}]
}, {
	path: "/cate",
	component: Layout,
	redirect: '/cate/item_rank',
	meta: { title: "品类", subtitle: '品类罗盘', icon: "category" },
	children: [{
		path: "macro_monitor",
		name: "SycmCateMacroMonitor",
		component: () => import('@/views/sycm/category/macroMonitor'),
		meta: {
			title: "宏观监控"
		}
	}, {
		path: "item_rank",
		name: "SycmCateItemRank",
		component: () => import('@/views/sycm/category/itemRank'),
		meta: {
			title: "商品排行"
		}
	}]
}, {
	path: "/trade",
	component: Layout,
	redirect: '/trade/overview',
	meta: { title: "交易", subtitle: '交易分析', icon: "trade" },
	children: [{
		path: "overview",
		name: "SycmTradeOverview",
		component: () => import('@/views/sycm/trade/overview'),
		meta: {
			title: "交易概况"
		}
	}, {
		path: "constitute",
		name: "SycmTradeConstitute",
		component: () => import('@/views/sycm/trade/constitute'),
		meta: {
			title: "交易构成"
		}
	}]
}, {
	path: "/lives",
	component: Layout,
	redirect: '/lives/overview',
	meta: { title: "直播", icon: "lives" },
	children: [{
		path: "overview",
		name: "SycmLivesOverview",
		component: () => import('@/views/sycm/lives/overview'),
		meta: {
			title: "直播概况"
		}
	}, {
		path: "achievement",
		name: "SycmLivesAchievement",
		component: () => import('@/views/sycm/lives/achievement'),
		meta: {
			title: "直播间业绩"
		}
	}, {
		path: "data",
		name: "SycmLivesData",
		component: () => import('@/views/sycm/lives/data'),
		meta: {
			title: "本店商品成交"
		}
	}]
}, {
	path: '*',
	hidden: true,
	redirect: '/portal/home'
}]
export default sycmRouter
