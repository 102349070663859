import Vue from 'vue'
import App from '@/App.vue'

import ElementUI from 'element-ui'
import './assets/css/element-variables.scss'

import router from './router'
import store from './store'
import i18n from './lang'
import './assets/icons'
import './permission'
import './prototype'
import * as filters from './filters'

import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

import 'fullpage.js/vendors/scrolloverflow' // Optional. When using scrollOverflow:true
// import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
import VueFullPage from 'vue-fullpage.js'
Vue.use(VueFullPage)

import { Swiper as SwiperClass,Navigation, Pagination, Mousewheel,Autoplay } from 'swiper/swiper.esm'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'
SwiperClass.use([Navigation, Pagination, Mousewheel, Autoplay])
Vue.use(getAwesomeSwiper(SwiperClass))

Vue.use(ElementUI, {
	i18n:(key, value) => i18n.t(key, value)
})

// 百度地图
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap,{ak:'7GAxnzcDaWjbiLx19K3Hge1OKBuK5alo'})

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
