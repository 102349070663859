import live from './modules/live'
import liveplatform from './modules/liveplatform'
import login from './modules/login'
import mobile from './modules/mobile'
import teacher from './modules/teacher'
import seller from './modules/seller'
import sycm from './modules/sycm'

export default {
	live,
	liveplatform,
	login,
	mobile,
	teacher,
	seller,
	sycm
}