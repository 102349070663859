import Vue from 'vue'
import Router from 'vue-router'
import { getHost } from '@/utils/auth'

Vue.use(Router)

/* Router Modules */
import liveRouter from './modules/live'
import liveplatformRouter from './modules/liveplatform'
import loginRouter from './modules/login'
import mLoginRouter from './modules/m_login'
import homeRouter from './modules/home'
import mobileRouter from './modules/mobile'
import workRouter from './modules/work'
import sycmRouter from './modules/sycm'


// 获取原型对象上的push函数
const originalPush = Router.prototype.push
// 修改原型对象中的push方法
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

var constantRoutes = [{
		path: "/404",
		component: () => import('@/views/404.vue'),
		meta: {
			title: "404"
		}
	},
	{
		path: '*',
		redirect: '/404'
	}
]

const domain = process.env.VUE_APP_BASE_URL // 主域名
const host = getHost() // 当前域名
const pathname = window.location.pathname

if (host.indexOf('live.' + domain) !== -1) {
	constantRoutes = liveRouter	// 直播门户
} else if (host.indexOf('liveplatform.' + domain) !== -1) {
	constantRoutes = liveplatformRouter	// 中控台
} else if (host.indexOf('login.' + domain) !== -1) {
	// 登录
	if (pathname.indexOf('/m/') === 0) {
		constantRoutes = mLoginRouter
	} else {
		constantRoutes = loginRouter
	}
} else if (host.indexOf('item.' + domain) !== -1) {
	// if (pathname.indexOf('/m/') === 0) {
	// 	constantRoutes = mobileRouter
	// } else {
	// 	constantRoutes = homeRouter
	// }
	constantRoutes = mobileRouter
} else if (host.indexOf('work.' + domain) !== -1) {
	constantRoutes = workRouter	// 卖家中心
} else if (host.indexOf('sycm.' + domain) !== -1) {
	constantRoutes = sycmRouter	// 生意参谋
}

const createRouter = () => new Router({
	mode: 'history', // require service support
	scrollBehavior: () => ({
		y: 0
	}),
	routes: constantRoutes
})

const router = createRouter()

export default router
